import { Button, Collapse, Form, Switch } from "antd";
import { useEffect, useRef, useState } from "react";
import { ExternalLink, Save, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MessageModal from "../../components/MessageModal";
import { DEFAULT_SETUP_WEBSITE } from "../../constants/website";
import {
  useActiveWebApi,
  useGetWebsiteApi,
  useSetupWebApi,
} from "../../services/apis/website.api";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import { TStatusRequest } from "../../types/common.type";
import { useListSetup } from "./ListSetup";
import { TCreateWebsite } from "../../types/website.type";
import { focusValidation, focusValidationId } from "../../utils/common";

const SetupWebsite = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const stickyRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [status, setStatus] = useState<TStatusRequest>(null);
  const mutationSetup = useSetupWebApi();
  const mutationActive = useActiveWebApi();
  const on = Form.useWatch("on", form);
  const {
    albums,
    videos,
    branches,
    pricing_images,
    intro_images,
    intro_background,
    initialData,
  } = useRootSelector((state) => state.website);
  const { data: website, refetch } = useGetWebsiteApi();
  const items = useListSetup(form);
  const dispatch = useDispatch();
  const activeWeb = on && website?.domain;

  const onFinish = (values: TCreateWebsite) => {
    //Check validate
    if (!intro_background) return focusValidationId("intro_background");
    if (intro_images.filter((item) => !!item).length === 0)
      return focusValidationId("intro_images");
    if (albums.length === 0) return focusValidationId("albums");

    const data: TCreateWebsite = {
      ...values,
      intro_images: intro_images.filter((item) => !!item),
      intro_background: intro_background,
      albums: albums.map((item) => item.id),
      videos: videos,
      video_layout: values.video_layout,
      pricing_images: pricing_images,
      pricing_note: values.pricing_note,
      branches: branches,
      contacts: {
        facebook: values.contacts.facebook,
        instagram: values.contacts.instagram,
        line: values.contacts.line,
        messenger: values.contacts.messenger,
        telegram: values.contacts.telegram,
        tiktok: values.contacts.tiktok,
        x: values.contacts.x,
        youtube: values.contacts.youtube,
        zalo: values.contacts.zalo,
      },
    };
    mutationSetup.mutate(data, {
      onSuccess() {
        setStatus({ status: "success", text: "" });
        refetch();
      },
      onError: (error: any) => {
        setStatus({
          status: "error",
          text: error.response.data.message || t("errorTitle"),
        });
      },
    });
  };

  useEffect(() => {
    form.setFieldsValue({ ...DEFAULT_SETUP_WEBSITE, ...website });
    if (!!website) {
      const data = {
        ...website,
        intro_images: [
          ...Array.from(
            { length: 3 },
            (_, index) => website.intro_images[index]
          ),
        ],
      };
      dispatch(setSetupWebsite({ ...data, initialData: data }));
    }
  }, [website]);

  useEffect(() => {
    const header = document.getElementById("header");
    const handleScroll = () => {
      if (stickyRef.current && header) {
        const stickyTop = stickyRef.current.getBoundingClientRect().top;
        const headerBottom = header.getBoundingClientRect().bottom;

        if (stickyTop < headerBottom && !isSticky) {
          setIsSticky(true);
        } else setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col items-center mb-8 md:mb-12">
      <div
        className={`max-w-[1440px] m-auto sm:px-8 px-4 flex flex-col items-center relative ${
          isSticky ? "sm:pt-24 pt-8" : "pt-8"
        }`}
      >
        <div className="text-slate-700 font-semibold text-xl sm:text-2xl">
          {t("formSetup.title")}
        </div>
        <div className="relative w-full h-[1px] bg-white text-slate-500 my-5 text-sm">
          <span className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-white px-3 whitespace-nowrap">
            {t("formSetup.subTitle")}
          </span>
        </div>
      </div>
      <Form
        form={form}
        name={`${!!website ? "edit" : "create"}-setup-website`}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={(errorInfo) => {
          focusValidation(
            errorInfo,
            `${!!website ? "edit" : "create"}-setup-website`
          );
        }}
        colon={false}
        className="w-full"
      >
        <div
          ref={stickyRef}
          className={`w-full ${
            isSticky
              ? "sm:fixed top-14 md:top-16 left-0 right-0 sm:bg-gray-100 sm:z-40 sm:py-4"
              : "py-4 sm:py-8"
          }`}
        >
          <div
            className={`w-full ${
              isSticky ? "max-w-[1440px] m-auto sm:px-8 px-4" : ""
            }`}
          >
            <div className="flex items-center relative gap-4 justify-between w-full sm:flex-row flex-col">
              <div className="flex gap-4">
                <div className="flex gap-2 items-center">
                  {t("formSetup.seeWeb")}
                  <ExternalLink
                    className="w-5 text-secondary cursor-pointer"
                    onClick={() => {
                      if (activeWeb) {
                        window.open(`https://${website.domain}`, "_blank");
                      } else if (on) {
                        setStatus({
                          status: "confirm",
                          title: t("formSetup.domainWarningTitle"),
                          text: t("formSetup.domainWarningText"),
                        });
                      } else {
                        setStatus({
                          status: "confirm",
                        });
                      }
                    }}
                  />
                </div>
                <div className="flex gap-2 items-center">
                  {t("formSetup.enable")}
                  <Form.Item name="on" layout="horizontal" className="mb-0">
                    <Switch
                      defaultChecked
                      className="w-fit"
                      onChange={() => {
                        mutationActive.mutate();
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="flex gap-4">
                <Button
                  type="primary"
                  onClick={() => {
                    const newData = !!website
                      ? initialData
                      : DEFAULT_SETUP_WEBSITE;
                    dispatch(setSetupWebsite(newData));
                    form.setFieldsValue(newData);
                  }}
                  className="transition-all font-medium"
                >
                  <X className="w-4 " />
                  {t("formSetup.resetBtn")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="transition-all font-medium success"
                  loading={mutationSetup.isLoading}
                  disabled={mutationSetup.isLoading}
                >
                  {!mutationSetup.isLoading && <Save className="w-4 " />}
                  {t("formSetup.saveBtn")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center relative w-full">
          <Collapse
            defaultActiveKey={items.map((item) => item.key as string)}
            className="w-full"
            items={items}
          />
        </div>
      </Form>
      <MessageModal
        isVisible={!!status}
        onClose={() => {
          setStatus(null);
        }}
        title={status?.title || t(`formSetup.${status?.status}`)}
        handleClick={() => {
          status.status === "success" &&
            activeWeb &&
            window.open(`https://${website.domain}`, "_blank");
        }}
        subTitle={status?.text}
        type={status?.status}
        btnText={
          status?.status === "success" && activeWeb
            ? t("formSetup.seeWeb")
            : status?.status !== "confirm" && t("btnClose")
        }
      />
    </div>
  );
};

export default SetupWebsite;

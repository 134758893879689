import { Button, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { Check, Clipboard, Download } from "react-feather";
import { useTranslation } from "react-i18next";
import { DOWNLOAD_TXT } from "../../constants/album";
import { TImage } from "../../types/album.type";
import {
  downloadTxtFile,
  formatGroupedData,
  groupByFolderAndTags,
  replaceContentTxt,
} from "../../utils/common";

const DEFAULT_TXT = [DOWNLOAD_TXT.COMMA, DOWNLOAD_TXT.SPACE];

const OptionsTxt = ({
  images,
  name,
  isTag,
  isComment,
}: {
  images: TImage[];
  name: string;
  isTag: boolean;
  isComment: boolean;
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(null);
  const [status, setStatus] = useState<"copy" | "download">(null);
  let options = [
    {
      label: t(`dashboard.albums.action.download.txt.${DOWNLOAD_TXT.COMMA}`),
      value: DOWNLOAD_TXT.COMMA,
      join: ",",
      show: true,
    },
    {
      label: t(`dashboard.albums.action.download.txt.${DOWNLOAD_TXT.SPACE}`),
      value: DOWNLOAD_TXT.SPACE,
      join: " ",
      show: true,
    },
    {
      label: t(`dashboard.albums.action.download.txt.${DOWNLOAD_TXT.DOWNLINE}`),
      value: DOWNLOAD_TXT.DOWNLINE,
      join: "\n",
      show: true,
    },
    {
      label: t(
        `dashboard.albums.action.download.txt.${DOWNLOAD_TXT.EXTENTION}`
      ),
      value: DOWNLOAD_TXT.EXTENTION,
      join: "",
      show: true,
    },
    {
      label: t(`dashboard.albums.action.download.txt.${DOWNLOAD_TXT.COMMENT}`),
      value: DOWNLOAD_TXT.COMMENT,
      join: "\n",
      show: isComment,
    },
  ];
  const handleChange = (values: string[]) => {
    const valueJoin = Array.from(
      new Set(
        values
          .map((item) =>
            options
              .filter((option) => option.show)
              .find((option) => option.value === item)?.join
          )
      )
    ).join("");
    const valueExtention = values.includes(DOWNLOAD_TXT.EXTENTION);
    const isComment = values.includes(DOWNLOAD_TXT.COMMENT);
    const groupImages = groupByFolderAndTags(
      images,
      isTag,
      valueExtention,
      isComment
    );
    setValue(formatGroupedData(groupImages, valueJoin));
  };

  useEffect(() => {
    if (!!images) handleChange(DEFAULT_TXT);
  }, [images]);

  return (
    <div className="flex flex-col gap-2">
      <span>{t("dashboard.albums.action.download.txt.list")}</span>
      <Checkbox.Group
        options={options
          .filter((item) => item.show)
          .map((item) => ({
            value: item.value,
            label: item.label,
          }))}
        defaultValue={DEFAULT_TXT}
        onChange={(values) => {
          handleChange(values);
        }}
        className={`grid grid-cols-2 md:flex justify-between items-center gap-2`}
      />
      <div
        className="bg-stone-200 text-gray-500 rounded-lg py-2 px-3 whitespace-pre-line min-h-48 cursor-default"
        dangerouslySetInnerHTML={{ __html: value }}
      />

      <div className="w-full grid grid-cols-2 gap-4">
        <Button
          type="default"
          htmlType="submit"
          className={`my-4 transition-all success`}
          onClick={() => {
            navigator.clipboard
              .writeText(replaceContentTxt(value))
              .then(() => {});
            setStatus("copy");
            setTimeout(() => setStatus(null), 1000);
          }}
          disabled={images?.length === 0}
        >
          {status === "copy" ? (
            <Check className="w-4" />
          ) : (
            <Clipboard className="w-4" />
          )}
          {t("dashboard.albums.action.download.modalShare.btnCopy")}
        </Button>
        <Button
          type="default"
          htmlType="submit"
          className={`my-4 transition-all success ${
            status === "download" ? "opacity-70" : ""
          }`}
          disabled={images?.length === 0}
          loading={status === "download"}
          onClick={() => {
            downloadTxtFile(value, name);
          }}
        >
          {status !== "download" && <Download className="w-4" />}
          {t("dashboard.albums.action.download.photos.btn")}
        </Button>
        {status === "download" && (
          <span className="text-center text-orange-600 whitespace-pre-line">
            {t("dashboard.albums.action.download.photos.loading")}
          </span>
        )}
      </div>
    </div>
  );
};

export default OptionsTxt;

import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { TUserProfile } from "../types/user.type";
import { createContextualCan } from "@casl/react";
import { createContext } from "react";
import {
  INFINITY_NUMBER_ALBUM,
  INFINITY_NUMBER_BRANCH,
} from "../constants/commons";

export default function defineAbilityUser(user: TUserProfile | undefined) {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  if (!user) {
    return build();
  }

  user.membership_plan.plan_permissions?.forEach((permission) => {
    if (permission.number) {
      can("can", permission.name);
    }
    if (permission.name === PERMISSION.NUM_ALBUM) {
      if (user.album_in_month < permission.number) {
        can("can", PERMISSION.CREATE_ALBUM);
      }
    } else if (permission.name === PERMISSION.NUM_BRANCH) {
      can("add", PERMISSION.NUM_BRANCH, { max: { $lt: permission.number } });
      if (permission.number >= INFINITY_NUMBER_BRANCH) {
        can("addInfinity", PERMISSION.NUM_BRANCH);
      }
    } else if (permission.name === PERMISSION.NUM_ALBUM_WEBSITE) {
      can("add", PERMISSION.NUM_ALBUM_WEBSITE, {
        max: { $lt: permission.number },
      });
      if (permission.number >= INFINITY_NUMBER_ALBUM) {
        can("addInfinity", PERMISSION.NUM_ALBUM_WEBSITE);
      }
    }
  });

  return build();
}

export const PERMISSION = {
  NUM_ALBUM: "user.num_album",
  EXPIRE_TIME: "user.expire_time",
  FACE_FIDER: "user.face_fider",
  CHANGE_LOGO: "user.change_logo",
  DOWNLOAD_IMAGE: "user.download_image",
  PRIVATE_DOMAIN: "user.private_domain",
  CREATE_ALBUM: "user.create_album",
  NUM_ALBUM_WEBSITE: "user.num_album_website",
  LOGO_COPYRIGHT: "user.logo_copyright",
  NUM_BRANCH: "user.num_branch",
};

export const defaultAbility = defineAbilityUser(undefined);

export const AbilityContext = createContext(defaultAbility);

export const Can = createContextualCan(AbilityContext.Consumer);

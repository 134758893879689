import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { Heart, MessageSquare, Star, Tag } from "react-feather";
import { useTranslation } from "react-i18next";
import { DOWNLOAD_PHOTOS } from "../../constants/album";
import { useGetAlbumApi } from "../../services/apis/album.api";
import { IDownloadAlbum, TDownloadAlbum } from "../../types/album.type";
import OptionsTxt from "./OptionsTxt";

const DownloadTxt = (props: IDownloadAlbum) => {
  const { id, status, totalPhotos, tags } = props;
  const { t } = useTranslation();
  const dfOption = Object.keys(status).find(
    (item) => status[item]
  ) as TDownloadAlbum;
  const [value, setValue] = useState<TDownloadAlbum>(dfOption);
  const options = {
    [value]: value !== DOWNLOAD_PHOTOS.TAG ? 1 : tags?.map((item) => item.id),
  };
  const { data, isLoading, refetch } = useGetAlbumApi({
    perPage: totalPhotos,
    albumIdProps: id,
    filterParams: !!value && options,
    enabled: !!dfOption && status[value],
  });

  useEffect(() => {
    if (!!value) {
      const refetchData = async () => {
        await refetch();
      };
      refetchData();
    }
  }, [value]);

  const items = [
    {
      key: DOWNLOAD_PHOTOS.LIKE,
      icon: <Heart className="w-4" />,
    },
    {
      key: DOWNLOAD_PHOTOS.TAG,
      icon: <Tag className="w-4" />,
    },
    {
      key: DOWNLOAD_PHOTOS.COMMENT,
      icon: <MessageSquare className="w-4" />,
    },
    {
      key: DOWNLOAD_PHOTOS.RECOMMEND,
      icon: <Star className="w-4" />,
    },
  ];

  return (
    <div className="flex flex-col">
      <Tabs
        activeKey={value}
        items={items.map((item) => ({
          key: item.key,
          disabled: isLoading || !status[item.key],
          label: (
            <div className="flex gap-1 items-center">
              {item.icon}
              {t(`dashboard.albums.action.download.txt.${item.key}`)}
            </div>
          ),
          children: (
            <OptionsTxt
              images={data?.pages[0]?.data?.data?.files?.data}
              name={data?.pages[0]?.data?.data?.name}
              isTag={item.key === DOWNLOAD_PHOTOS.TAG}
              isComment={item.key === DOWNLOAD_PHOTOS.COMMENT}
            />
          ),
        }))}
        onTabClick={async (key: TDownloadAlbum, _e) => {
          setValue(key);
        }}
        className="flex justify-between"
      />
    </div>
  );
};

export default DownloadTxt;

import { Modal, Skeleton, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MessageModal, { TMessageModal } from "../../components/MessageModal";
import {
  paymentSePayCheck,
  paymentSePayCreate,
  useSePayCreateApi,
} from "../../services/apis/payment.api";
import { setIsCloseAllModal } from "../../stores/reducers/payment.reducer";
import { Check, Clipboard } from "react-feather";
import { TTransaction } from "../../types/membership.type";

type KeySePayAccount = "accountNumber" | "bank" | "amount" | "reference";

type ContentSePay = {
  key: KeySePayAccount;
  label: string;
  content: string;
};

export default function SePay({ subscriptionId }: { subscriptionId: number }) {
  const { t } = useTranslation();
  const [values, setValues] = useState<Partial<TTransaction>>(null);
  const { mutate: createMutation, isLoading: createLoading } =
    useSePayCreateApi();
  const [isModalOpen, setIsModalOpen] = useState<Omit<
    TMessageModal,
    "isVisible"
  > | null>(null);
  const [isCopy, setIsCopy] = useState<KeySePayAccount>(null);
  const dispatch = useDispatch();

  const onClick = () => {
    createMutation(
      { subscription_id: subscriptionId },
      {
        onSuccess: (res) => {
          setValues({
            amount: res.data.transaction.amount,
            reference: res.data.transaction.reference,
          });
        },
      }
    );
  };

  const sepayItems: ContentSePay[] = [
    {
      key: "accountNumber",
      label: t("servicePackages.payment.numberAccount"),
      content: "0983192901",
    },
    {
      key: "bank",
      label: t("servicePackages.payment.bankAccount"),
      content: "VPBANK (Ngân hàng Thương mại cổ phần Việt Nam Thịnh Vượng)",
    },
    {
      key: "amount",
      label: t("servicePackages.payment.total"),
      content: values?.amount?.toLocaleString("de-DE"),
    },
    {
      key: "reference",
      label: t("servicePackages.payment.content"),
      content: values?.reference,
    },
  ];

  useEffect(() => {
    if (!!values) {
      const interval = setInterval(() => {
        paymentSePayCheck({ reference: values.reference })
          .then((res) => {
            dispatch(setIsCloseAllModal(true));
            clearInterval(interval);
            setIsModalOpen({
              title: "SePay",
              subTitle: res.data.message,
              type: "success",
              btnText: t("btnClose"),
              onClose() {
                window.location.reload();
              },
            });
          })
          .catch((err) => {});
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [values]);

  return (
    <>
      <div
        className="bg-[#377dff] text-white cursor-pointer rounded-[4px] flex justify-center items-center text-base h-full hover:opacity-70 transition-all"
        onClick={onClick}
      >
        {/* <img
          src={sepayImg}
          alt="sepay-img"
          className="h-3/4 m-auto object-contain"
        /> */}
        {t("servicePackages.payment.sepay")}
      </div>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("servicePackages.payment.sepay")}
          </div>
        }
        open={!!values}
        onCancel={() => {
          setValues(null);
        }}
        footer={null}
        destroyOnClose={true}
        className="h-[500px]"
      >
        {createLoading ? (
          <div className="size-full overflow-hidden flex flex-col gap-4">
            <Skeleton active />
            <Skeleton active />
          </div>
        ) : (
          <div className="flex flex-col gap-1 text-sm">
            {sepayItems.map((item, index) => (
              <div
                key={index}
                className="flex flex-row gap-4 md:gap-8 justify-between"
              >
                <div className="text-gray-500 w-1/3">{item.label}</div>
                <div className="flex gap-2 flex-1 w-full text-right justify-end">
                  <span className="flex-1 w-full">{item.content}</span>
                  <div
                    className="-mt-2 text-secondary flex items-center justify-center cursor-pointer size-7 rounded-2xl hover:bg-[#0bcdac26] transition-all"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(item.content)
                        .then(() => {});
                      setIsCopy(item.key);
                      setTimeout(() => setIsCopy(null), 1000);
                    }}
                  >
                    {isCopy === item.key ? (
                      <Check className="w-4" />
                    ) : (
                      <Clipboard className="w-4" />
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="text-gray-500">{t("QR Code") + ":"}</div>
            <div className="relative mx-auto my-2 md:size-44 size-28">
              {!!values ? (
                <img
                  src={`https://qr.sepay.vn/img?acc=0983192901&bank=VPB&amount=${values?.amount}&des=${values?.reference}`}
                  alt="sepay-img-qr"
                />
              ) : (
                <div className="size-full flex items-center justify-center">
                  <Spin className="m-auto" />
                </div>
              )}
            </div>
            <span className="text-center text-orange-600 whitespace-pre-line">
              {t("servicePackages.payment.noteSepay")}
            </span>
          </div>
        )}
      </Modal>
      <MessageModal
        isVisible={!!isModalOpen}
        {...isModalOpen}
        handleClick={() => {
          dispatch(setIsCloseAllModal(false));
        }}
      />
    </>
  );
}

import { AxiosError, AxiosResponse } from "axios";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { queryKeys } from "../../constants/queryKeys";
import { setIsAuthenticated } from "../../stores/reducers/auth.reducer";
import store from "../../stores/reducers/root";
import { setUser } from "../../stores/reducers/user.reducer";
import { TGetProfileResponse, TNotificationResponse } from "../../types/user.type";
import { userEndpoint } from "../endpoints";
import http from "../http.service";
import { createURLSearchParams } from "../../utils/common";

export const useGetMeApi = (enabled?: boolean) => {
  const dispath = useDispatch();
  const query = useQuery(
    [queryKeys.GET_ME],
    async () => {
      const res = await http.get<TGetProfileResponse>(userEndpoint.PROFILE);
      return res;
    },
    {
      enabled: enabled,
      onSuccess: (data) => {
        if (data?.data.data) {
          dispath(setUser(data?.data.data));
        }
      },
      onError: () => {
        store.dispatch(setIsAuthenticated(false));
      },
    }
  );

  return {
    refetch: query.refetch,
    profile: query.data?.data?.data,
  };
};

export const useUpdateProfileApi = () => {
  const mutation = useMutation<
    AxiosResponse<{ success: boolean }, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async (data: any) => {
      const url = `${userEndpoint.PROFILE}`;
      return await http.post<{ success: boolean }>(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
  return mutation;
};
export const useUpdateSetting = () => {
  const mutation = useMutation<
    AxiosResponse<{ success: boolean }, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async (data: any) => {
      const url = `${userEndpoint.SETTING}`;
      return await http.post<{ success: boolean }>(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
  return mutation;
};

export const fetchCheckDomain = async () => {
  const res = await http.get<any>(userEndpoint.CHECK_DOMAIN);
  return res;
};

export const useFetchNotification = () => {
  const query = useInfiniteQuery(
    [
      queryKeys.GET_NOTIFICATION,
    ],
    async ({ pageParam = 1 }) => {
      const params = createURLSearchParams({
        page: pageParam,
      });
      const url = `${userEndpoint.NOTIFICATION}?per_page=5&${params}`;
      const res = await http.get<TNotificationResponse>(url);
      return res;
    },
    {
      getNextPageParam: (res) => {
        const lastPage = res?.data?.meta?.last_page;
        const currentPage = res?.data?.meta?.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );

  return query;
};

export const useMarkAllAsRead = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<AxiosResponse<{ success: boolean }, any>, AxiosError<any>, any, unknown>(
    async () => {
      const url = `${userEndpoint.MARK_ALL_AS_READ}`;
      return await http.post<{ success: boolean }>(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.GET_NOTIFICATION);
      },
    }
  );
  return mutation;
};

export const useMarkAsRead = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<AxiosResponse<{ success: boolean }, any>, AxiosError<any>, number, unknown>(
    async (id: number) => {
      const url = `${userEndpoint.MARK_AS_READ(id)}`;
      return await http.post<{ success: boolean }>(url);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.GET_NOTIFICATION);
      },
    }
  );
  return mutation;
};

import { useMutation } from "react-query";
import { TUploadPhotoResponse } from "../../types/common.type";
import { commonEndpoint } from "../endpoints";
import http from "../http.service";

export const useUploadPhotoApi = () => {
  const mutation = useMutation({
    mutationFn: async (dataQuery: any) => {
      return await http.post<TUploadPhotoResponse>(
        commonEndpoint.UPLOAD_PHOTO,
        dataQuery,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
  });
  return mutation;
};

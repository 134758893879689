import { useMemo, useState, useRef, useEffect, useCallback } from "react";
import { Dropdown, Badge, Spin } from "antd";
import { Bell, Info, Link2 } from "react-feather";

import {
  useFetchNotification,
  useMarkAllAsRead,
  useMarkAsRead,
} from "../../services/apis/user.api";
import { TNotification } from "../../types/user.type";
import { useTranslation } from "react-i18next";
import { IoCheckmarkDone, IoNotifications } from "react-icons/io5";
const Notification = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useFetchNotification();
  const { mutate: markAllAsRead } = useMarkAllAsRead();
  const { t } = useTranslation();
  const notifications: TNotification[] = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...page?.data?.data];
    }, []);
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest("#notification-menu")
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [open]);

  useEffect(() => {
    let timeout = null;
    if (open === false) {
      timeout = setInterval(() => {
        refetch();
      }, 1000 * 60);
    }
    return () => clearInterval(timeout);
  }, [open, refetch]);

  const loadMore = () => {
    fetchNextPage();
  };

  const menuContent = (
    <div
      className="w-60 md:w-80 bg-white shadow-lg rounded-lg p-2"
      id="notification-menu"
    >
      <div className="flex justify-between items-center px-2 pb-2 border-b-2 mb-1">
        <span className="font-semibold text-lg">{t("Notification")}</span>
        <div
          className="flex items-center gap-2 cursor-pointer text-secondary px-3 py-[2px] hover:bg-[#0bcdac26] rounded-full"
          onClick={() => {
            markAllAsRead(true);
            setOpen(false);
          }}
        >
          <span className="mt-1">{t("Read all")}</span>
          <IoCheckmarkDone className="text-lg" />
        </div>
      </div>

      {!!notifications && notifications.length > 0 ? (
        <div className="max-h-60 overflow-y-auto border-b border-gray-200">
          {notifications?.map((item) => (
            <NotificationItem key={item.id} item={item} />
          ))}

          {isLoading && (
            <div className="text-center p-2">
              <Spin size="small" />
            </div>
          )}
          {hasNextPage && (
            <div
              className="text-center text-secondary cursor-pointer p-2 hover:bg-gray-100 rounded-lg"
              onClick={loadMore}
            >
              Load More
            </div>
          )}
        </div>
      ) : (
        <div className="p-6 flex flex-col items-center gap-2 text-gray-500">
          <IoNotifications className="text-4xl -rotate-12" />
          <span className="text-lg font-semibold text-gray-400">
            {t("noNotication")}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div ref={dropdownRef}>
      <Dropdown
        dropdownRender={() => menuContent}
        trigger={["click"]}
        open={open}
        onOpenChange={setOpen}
        placement="bottomRight"
      >
        <Badge
          count={data?.pages[0]?.data?.meta?.total || 0}
          size="small"
          className="mt-2"
        >
          <Bell className="w-5 cursor-pointer" onClick={() => setOpen(!open)} />
        </Badge>
      </Dropdown>
    </div>
  );
};

export default Notification;

const NotificationItem = ({ item }: { item: TNotification }) => {
  const { i18n } = useTranslation();
  const { mutate: markAsRead } = useMarkAsRead();
  const getLink = useCallback((item: TNotification) => {
    switch (item.effectable_type) {
      case "Album":
        return `/dashboard/albums/${item.effectable_id}`;
      case "Subscription":
        return `/dashboard/package-management`;
    }

    if (item.url) {
      return item.url;
    }

    return null;
  }, []);

  return getLink(item) ? (
    <div
      key={item.id}
      onClick={() => {
        window.open(getLink(item), "_blank");
        markAsRead(item.id);
      }}
      className="flex p-2 hover:bg-gray-100 rounded-lg cursor-pointer gap-3 items-center mb-1"
    >
      <Link2 className="w-5 h-5 m-auto" />
      <div className="flex-1">
        <div
          dangerouslySetInnerHTML={{
            __html: i18n.language === "vi" ? item.content.vi : item.content.en,
          }}
        />
      </div>
    </div>
  ) : (
    <div className="flex p-2 hover:bg-gray-100 rounded-lg cursor-pointer gap-3 items-center">
      <Info className="w-5 h-5" />
    </div>
  );
};

import { Button, Form, Input, Radio, Select, Spin } from "antd";
import { TAlbumDetail } from "../../types/album.type";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCopyDrive,
  checkStatusCopy,
  useGetAlbumApi,
} from "../../services/apis/album.api";
import { CheckCircleFilled } from "@ant-design/icons";
import { TImage } from "../../types/album.type";
type TFilterDriveAlbumProps = {
  album: TAlbumDetail;
  type: "drive" | "local";
};
const { Option } = Select;

declare global {
  interface Window {
    showDirectoryPicker: () => Promise<FileSystemDirectoryHandle>;
  }
}

const FilterAndSaveAlbum = (props: TFilterDriveAlbumProps) => {
  const { album } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [link, setLink] = useState<string | null>(null);
  const [isCopy, setIsCopy] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const filterType = Form.useWatch("filter_type", form);
  const tagIds = Form.useWatch("tag_ids", form);
  const [desDir, setDesDir] = useState<any>(null);
  const [srcDir, setSrcDir] = useState<any>(null);
  const { mutate: copyDriveToFolder, isLoading: loadingCopyDriveToFolder } =
    useCopyDrive();
  const [option, setOption] = useState<string>("");
  const [listFileName, setListFileName] = useState<string>("");
  const [extension, setExtension] = useState<string>("");
  const options = {
    [filterType]: filterType !== "tagged" ? 1 : 0,
  };
  const { data, isLoading, refetch } = useGetAlbumApi({
    perPage: album.file_count,
    albumIdProps: album.id,
    filterParams: !!option && options,
    tagIds: tagIds,
    enabled: false,
  });

  const optionExtension = [
    {
      value: "",
      label: t("All"),
    },
    {
      value: "jpeg,jpg",
      label: "JPEG (.jpg, .jpeg)",
    },
    {
      value: "png", 
      label: "PNG (.png)",
    },
    {
      value: "gif",
      label: "GIF (.gif)", 
    },
    {
      value: "bmp",
      label: "BMP (.bmp)",
    },
    {
      value: "tiff,tif",
      label: "TIFF (.tif, .tiff)",
    },
    {
      value: "heif,heic",
      label: "HEIF (.heic, .heif)",
    },
    {
      value: "webp",
      label: "WEBP (.webp)",
    },
    {
      value: "crw",
      label: "CRW (.crw)",
    },
    {
      value: "cr2",
      label: "CR2 (.cr2)",
    },
    {
      value: "cr3", 
      label: "CR3 (.cr3)",
    },
    {
      value: "nef",
      label: "NEF (.nef)",
    },
    {
      value: "nrw",
      label: "NRW (.nrw)",
    },
    {
      value: "arw",
      label: "ARW (.arw)",
    },
    {
      value: "srf",
      label: "SRF (.srf)",
    },
    {
      value: "sr2",
      label: "SR2 (.sr2)",
    },
    {
      value: "raf",
      label: "RAF (.raf)",
    },
    {
      value: "rw2",
      label: "RW2 (.rw2)",
    },
    {
      value: "raw",
      label: "RAW (.raw)",
    },
    {
      value: "orf",
      label: "ORF (.orf)",
    },
    {
      value: "pef",
      label: "PEF (.pef)",
    },
    {
      value: "dng",
      label: "DNG (.dng)",
    },
    {
      value: "rwl",
      label: "RWL (.rwl)",
    },
    {
      value: "3fr",
      label: "3FR (.3fr)",
    },
    {
      value: "fff",
      label: "FFF (.fff)",
    },
    {
      value: "x3f",
      label: "X3F (.x3f)",
    },
    {
      value: "iiq",
      label: "IIQ (.iiq)",
    }
  ];

  useEffect(() => {
    const files = data?.pages[0]?.data?.data?.files?.data as TImage[];
    let listFile = "";
    if (
      (option && option !== "custom" && option !== "tagged") ||
      (tagIds?.length && option === "tagged")
    ) {
      listFile = files
        ?.map((file) => {
          const name = file?.file_name ?? "";
          const lastDot = name.lastIndexOf(".");
          return lastDot !== -1 ? name.substring(0, lastDot) : name;
        })
        .join(",");
    }
    setListFileName(listFile);
  }, [data]);

  useEffect(() => {
    if (
      (option && option !== "custom" && option !== "tagged") ||
      (tagIds?.length && option === "tagged")
    ) {
      refetch();
    }
  }, [option, refetch, tagIds]);

  let interval: NodeJS.Timeout;

  const handleStartFilter = () => {
    if (props.type === "drive") {
      filterSaveGoogleDrive();
    } else {
      filterSaveLocal();
    }
  };

  const filterSaveGoogleDrive = () => {
    setLink(null);
    copyDriveToFolder(
      {
        id: album.id,
        data: {
          tag_ids: form.getFieldValue("tag_ids"),
          is_liked: filterType === "is_liked",
          is_commented: filterType === "is_commented",
          folder_name: form.getFieldValue("folder_name"),
        },
      },
      {
        onSuccess: (data) => {
          if (data.data.success) {
            setIsChecking(true);
            interval = setInterval(() => {
              checkStatusCopy(data.data.folder_id)
                .then((res) => {
                  if (res.data.success === true) {
                    setLink(
                      "https://drive.google.com/drive/folders/" +
                        data.data.folder_id
                    );
                    setIsChecking(false);
                    setError(null);
                    clearInterval(interval);
                  }
                })
                .catch((err) => {
                  setIsChecking(false);
                  clearInterval(interval);
                });
            }, 1000);
          }
        },
        onError: (err) => {
          console.log(err);
          setError(
            err.response.data?.message ||
              t("Create folder & copy images failed")
          );
        },
      }
    );
  };

  const filterSaveLocal = async () => {
    const copied: string[] = [];
    if (!srcDir || !desDir) {
      setError(t("Choose Folder Source and Destination"));
      return;
    }
    const extensionArray = extension.split(",");

    const fileNames = listFileName
      .split(",")
      .map((name) => name.toLowerCase().trim().normalize("NFC"));
    setIsChecking(true);
    for await (const [name, entry] of srcDir.entries()) {
      const nameLower = name.toLowerCase().trim().normalize("NFC");
      const fileExt = nameLower.split(".").pop() ?? "";
      const nameWithoutExt = nameLower.substring(0, nameLower.lastIndexOf("."));

      if (
        entry.kind === "file" &&
        fileNames.includes(nameWithoutExt) &&
        (extension === "" || extensionArray.includes(fileExt))
      ) {
        const file = await entry.getFile();
        const newFileHandle = await desDir.getFileHandle(name, { create: true });
        const writable = await newFileHandle.createWritable();
        await writable.write(await file.arrayBuffer());
        await writable.close();
        copied.push(name);
      }
      if (copied.length === fileNames.length) {
        setLink(desDir.name);
        setError(null);
      }
    }
    setIsChecking(false);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleStartFilter}
        initialValues={{
          filter_type: props.type === "drive" ? "is_liked" : "custom",
          tag_ids: [],
        }}
      >
        <Form.Item
          label="Vui lòng chọn mục bạn muốn lọc"
          name="filter_type"
          rules={[
            { required: true, message: "Vui lòng chọn mục bạn muốn lọc" },
          ]}
        >
          <Radio.Group
            className="flex flex-col space-y-2"
            onChange={(e) => {
              props.type === "local" && setOption(e.target.value);
              if (e.target.value === "custom") {
                form.setFieldValue("list_file_name", "");
              }
            }}
          >
            <Radio
              value="custom"
              className={`${props.type === "local" ? "" : "hidden"}`}
            >
              {t("albumDetail.filter.custom")}
            </Radio>
            <Radio value="is_liked">{t("albumDetail.filter.like")}</Radio>
            <Radio value="is_commented">
              {t("albumDetail.filter.comment")}
            </Radio>
            <Radio
              value="tagged"
              className="show-radio"
              disabled={!album.tags?.length}
            >
              {t("albumDetail.filter.tag")}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {filterType === "tagged" && (
          <Form.Item name="tag_ids">
            <Select
              className="w-full"
              mode="multiple"
              placeholder={t("Choose") + " tag"}
            >
              {album.tags?.map((tag) => (
                <Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <div
          className={`flex flex-col space-y-2 ${
            props.type === "drive" ? "hidden" : ""
          }`}
        >
          <div className="flex flex-col space-y-2 w-full">
            {isLoading && <Spin />}
            <Input.TextArea
              value={listFileName}
              onChange={(e) => {
                setListFileName(e.target.value);
              }}
              placeholder={t("Enter File Name (separated by commas)")}
              className="disabled:text-gray-600"
              disabled={filterType !== "custom"}
              rows={5}
            />
          </div>
          <div className="flex space-x-2 justify-center w-full">
            <div className="text-sm flex-1">
              {t("Extension file want to filter")}
            </div>

            <Select
              className="w-40"
              value={extension}
              defaultValue=""
              onChange={(value) => setExtension(value)}
            >
              {optionExtension.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex space-x-2 justify-between w-full">
            <div className="text-sm">{t("Choose Folder Source")}</div>
            <Button
              type="primary"
              className="min-w-40 max-w-48"
              onClick={async () => {
                try {
                  const dir = await window.showDirectoryPicker();
                  setSrcDir(dir);
                } catch (error) {
                  setSrcDir(null);
                  console.log(error);
                }
              }}
            >
              <div className="truncate">
                {srcDir ? t("Src: ") + srcDir?.name : t("Choose Folder")}
              </div>
            </Button>
          </div>
          <div className="flex space-x-2 justify-between w-full">
            <div className="text-sm">{t("Choose Folder Destination")}</div>
            <Button
              type="primary"
              className="min-w-40 max-w-48"
              onClick={async () => {
                try {
                  const dir = await window.showDirectoryPicker();
                  console.log(dir);
                  setDesDir(dir);
                } catch (error) {
                  setDesDir(null);
                  console.log(error);
                }
              }}
            >
              <div className="truncate">
                {desDir ? t("Des: ") + desDir?.name : t("Choose Folder")}
              </div>
            </Button>
          </div>
        </div>

        <Form.Item
          hidden={props.type === "local"}
          label={t("Enter Folder Name save images after filtered")}
          name="folder_name"
        >
          <div className="flex space-x-2">
            <Input
              placeholder={t("Enter Folder Name")}
              required={props.type === "drive"}
            />
          </div>
        </Form.Item>
        <div className="flex space-x-3 mt-4 justify-center">
          <Button
            size="large"
            className="bg-blue-500 text-white"
            loading={loadingCopyDriveToFolder}
            htmlType="submit"
          >
            {t("Start Filter")}
          </Button>
          <Button
            size="large"
            hidden={props.type === "local"}
            disabled={!link}
            className="bg-blue-500 text-white"
            onClick={() => window.open(link, "_blank")}
          >
            {t("View")}
          </Button>
          <Button
            size="large"
            hidden={props.type === "local"}
            disabled={!link}
            className="bg-blue-500 text-white"
            onClick={() => {
              navigator.clipboard.writeText(link);
              setIsCopy(true);
              setTimeout(() => {
                setIsCopy(false);
              }, 2000);
            }}
          >
            {t("Copy Link")}
            {isCopy && <CheckCircleFilled className="text-green-500" />}
          </Button>
        </div>
        {isChecking && (
          <div className="text-center mt-4 text-blue-500">
            {t("Doing create folder & copy images, please wait...")}
          </div>
        )}
        {link && (
          <div className="text-center mt-4 text-green-500">
            {t("Copy images success")}
          </div>
        )}
        {error && <div className="text-center mt-4 text-error">{error}</div>}

        {props.type === "local" && (
          <div className="text-center mt-4 text-gray-400">
            {t(
              "This feature is only fully available for Chrome and Edge browsers"
            )}
          </div>
        )}
      </Form>
    </div>
  );
};

export default FilterAndSaveAlbum;

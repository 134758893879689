import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { Heart, Icon, Image, MessageSquare, Star } from "react-feather";
import { useTranslation } from "react-i18next";
import { FILTER_PHOTO } from "../../constants/commons";
import { TFilterPhotoKeys, TOption } from "../../types/common.type";

type TFilterAlbumProps = {
  setIsFilter: (value: any) => void;
  counts: Record<TFilterPhotoKeys, number>;
};

type TOptionsFilter = TOption<string> & {
  count: number;
  icon: any;
};

const ICONS: Record<TFilterPhotoKeys, Icon> = {
  all: Image,
  recommend: Star,
  like: Heart,
  comment: MessageSquare,
};

const FilterAlbum = (props: TFilterAlbumProps) => {
  const { setIsFilter, counts } = props;
  const { t } = useTranslation();
  const [checkedList, setCheckedList] = useState([]);
  const optionFilter: TOptionsFilter[] = Object.keys(FILTER_PHOTO).map(
    (item) => ({
      value: FILTER_PHOTO[item],
      label: t(`albumDetail.filter.${item}`),
      icon: ICONS[item],
      count: counts[item],
    })
  );

  const onChangeFilter = (value: string) => {
    if (!value) {
      setCheckedList([]);
      return;
    }
    if (checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));
    } else setCheckedList([...checkedList, value]);
  };

  useEffect(() => {
    if (checkedList.length === 0) {
      setIsFilter(null);
    } else {
      setIsFilter(checkedList.reduce((a, v) => ({ ...a, [v]: 1 }), {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  return (
    <div className="flex sm:space-x-2 space-x-1 text-sm items-center flex-wrap sm:flex-nowrap">
      {optionFilter.map((item) => {
        return (
          <Tooltip title={item.label} key={item.value}>
            <div
              onClick={() => {
                onChangeFilter(item.value);
              }}
              className={`py-1 px-3 flex gap-1 items-center cursor-pointer transition-all rounded-3xl ${
                (checkedList.length === 0 && !item.value) ||
                checkedList.includes(item.value)
                  ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
                  : "hover:bg-gray-200/50"
              }`}
            >
              <item.icon className="w-4" />
              <div className="font-medium">{item.count}</div>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default FilterAlbum;

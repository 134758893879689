import { MessageSquare } from "react-feather";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowUpCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { path } from "../../routes/path";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-[1440px] m-auto sm:px-8 px-2 bg-white">
      <div className="flex flex-col lg:flex-row gap-6 lg::gap-8 lg:items-center sm:justify-between w-full pt-8 pb-4">
        <div className="flex flex-col justify-center sm:justify-start">
          <Link
            to={path.home}
            className="text-black text-3xl font-semibold text-center lg:text-left w-fit"
          >
            Shotpik
          </Link>
          <span className="font-semibold">{t("footer.info.company")}</span>
          <span>
            <span className="font-semibold">MST: 0315059335</span>
            {t("footer.info.tax")}
          </span>
          <span>{t("footer.info.address")}</span>
          <span>
            {t("footer.info.phone")}
            <a href="tel:+84819900003" className="font-semibold">
              0819900003
            </a>
          </span>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 items-start">
          <div className="flex flex-col">
            <Link to={"/page/terms-of-use"}>
              {t("footer.menu.terms-of-use")}
            </Link>
            <Link to={"/page/information-security-policy"}>
              {t("footer.menu.information-security-policy")}
            </Link>
            <Link to={"/page/payment-instructions"}>
              {t("footer.menu.payment-instructions")}
            </Link>
            <Link to={"/page/complaints-policy"}>
              {t("footer.menu.complaints-policy")}
            </Link>
          </div>
          <div className="flex flex-col">
            <Link to={"/page/delivery-policy"}>
              {t("footer.menu.delivery-policy")}
            </Link>
            <Link to={"/page/return-and-refund-policy"}>
              {t("footer.menu.return-and-refund-policy")}
            </Link>
            <Link to={"/page/buying-guide"}>
              {t("footer.menu.buying-guide")}
            </Link>
          </div>
          <div className="flex gap-6">
            <a
              href="https://m.me/NguyenHoangPhucPhoto"
              target="_blank"
              rel="noreferrer"
              className="flex gap-1 items-center text-third"
            >
              <MessageSquare className="w-6" />
            </a>
            <div
              className="cursor-pointer"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <HiOutlineArrowUpCircle className="text-3xl text-gray-500" />
            </div>
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: t("footer.copy"),
        }}
        className="text-sm text-center w-full py-4 border-t"
      ></div>
    </div>
  );
};

export default Footer;

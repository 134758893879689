import { Button } from "antd";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Contact,
  DomainLogo,
  Estimate,
  Generalsettings,
  Introduce,
  Products,
} from "../../components/SetupWebsite";
import {
  DEFAULT_CONTACT,
  DEFAULT_ESTIMATES,
  DEFAULT_GENERAL_SETTING,
  DEFAULT_INTRODUCE,
  DEFAULT_PRODUCTS,
} from "../../constants/website";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import {
  ISetupWebsiteFormProps,
  TCreateWebsite,
} from "../../types/website.type";

const useGenExtra = (
  dfValues: Partial<TCreateWebsite>,
  form: ISetupWebsiteFormProps["form"]
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { initialData } = useRootSelector((state) => state.website);
  const newData = !!initialData
    ? Object.keys(dfValues).reduce((acc, key) => {
        acc[key] = initialData[key] || dfValues[key];
        return acc;
      }, {})
    : dfValues;

  return (
    <Button
      type="primary"
      className="transition-all font-medium"
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setSetupWebsite(newData));
        form.setFieldsValue(newData);
      }}
    >
      <X className="w-4 " />
      {t("formSetup.resetBtn")}
    </Button>
  );
};

export const useListSetup = (form: ISetupWebsiteFormProps["form"]) => {
  const { t } = useTranslation();

  return [
    {
      key: "domainLogo",
      label: t("formSetup.domainLogo.title"),
      children: <DomainLogo />,
    },
    {
      key: "generalSetting",
      label: t("formSetup.generalSetting.title"),
      children: <Generalsettings />,
      extra: useGenExtra(DEFAULT_GENERAL_SETTING, form),
    },
    {
      key: "introduce",
      label: t("formSetup.introduce.title"),
      children: <Introduce form={form} />,
      extra: useGenExtra(DEFAULT_INTRODUCE, form),
    },
    {
      key: "products",
      label: t("formSetup.product.title"),
      children: <Products />,
      extra: useGenExtra(DEFAULT_PRODUCTS, form),
    },
    {
      key: "estimate",
      label: t("formSetup.estimate.title"),
      children: <Estimate />,
      extra: useGenExtra(DEFAULT_ESTIMATES, form),
    },
    {
      key: "contact",
      label: t("formSetup.contact.title"),
      children: <Contact />,
      extra: useGenExtra(DEFAULT_CONTACT, form),
    },
  ];
};

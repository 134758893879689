import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { ChevronsRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ggdrive from "../../assets/images/google-drive.svg";
import paneldarkroom from "../../assets/images/paneldarkroom.png";
import Footer from "../../components/Footer";
import MessageModal from "../../components/MessageModal";
import ContactBlock from "../../components/ServicePackages/ContactBlock";
import { useServicePackages } from "../../constants/servicePackages";
import { path } from "../../routes/path";
import { useGetMembershipPlansApi } from "../../services/apis/payment.api";
import { useGetMeApi } from "../../services/apis/user.api";
import { useRootSelector } from "../../stores/reducers/root";
import { TServicePackage } from "../../types/membership.type";
import { getPackageFromIdPrice, useCheckUnit } from "../../utils/membership";
import ModalPayment from "./ModalPayment";
import Package from "./Package";
import UpdatePhone from "../../components/Profile/UpdatePhone";

export type TModalPayment = {
  payment: number;
  havePhone: boolean;
};

const ServicePackages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useRootSelector(
    (state) => state.auth.isAuthenticated
  );
  const isCloseAllModal = useRootSelector(
    (state) => state.payment.isCloseAllModal
  );
  const { profile } = useGetMeApi();
  const [isModalOpen, setIsModalOpen] = useState<
    "login" | TModalPayment | undefined
  >(undefined);
  const { data: membershipPlans } = useGetMembershipPlansApi();
  const packages = useServicePackages();
  const checkUnit = useCheckUnit();
  const styleService: TServicePackage[] = [
    {
      id: 1,
      classNameCard: "rounded-3xl sm:rounded-none sm:rounded-l-3xl",
    },
    {
      id: 2,
      classNameCard:
        "rounded-3xl sm:rounded-none sm:rounded-tr-3xl lg:rounded-none",
    },
    {
      id: 3,
      classNameCard:
        "bg-[#d15354] text-white relative z-[0] before:absolute before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-[105%] before:h-[108%] before:z-[-1] before:bg-[#d15354] before:rounded-xl",
      classNameBtn: "bg-white text-primary border-white",
    },
    {
      id: 4,
      classNameCard:
        "rounded-3xl sm:rounded-none sm:rounded-br-3xl lg:rounded-r-3xl",
    },
  ];
  const listPackages: TServicePackage[] = packages?.map((service) => ({
    ...service,
    price:
      membershipPlans?.data
        ?.find((packageService) => packageService.id === service?.id)
        ?.membership_plan_price?.find((planPrice) => planPrice?.duration)?.[
        checkUnit
      ] || 0,
    name: membershipPlans?.data?.find((item) => item.id === service?.id)?.name,
    classNameCard: styleService?.find((item) => item?.id === service?.id)
      ?.classNameCard,
    classNameBtn: styleService?.find((item) => item?.id === service?.id)
      ?.classNameBtn,
    disable: profile?.subscription?.membership_plan_id > service.id,
  }));

  useEffect(() => {
    if (isCloseAllModal) setIsModalOpen(undefined);
  }, [isCloseAllModal]);

  return (
    <>
      <div className="flex flex-col items-center rounded-none">
        <div className="max-w-[1440px] m-auto sm:px-8 px-4 py-8 flex flex-col items-center service-packages">
          <div className="flex flex-col justify-center">
            <span className="text-3xl font-bold text-center">
              {t("servicePackages.intro.title")}
            </span>
            <span className="text-gray-700 text-center">
              {t("servicePackages.intro.subTitle")}
            </span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 py-10 relative">
            {listPackages.map((item, index) => {
              return (
                <Package
                  service={item}
                  key={index}
                  onClick={() => {
                    if (isAuthenticated && item?.key === "Free") {
                      navigate(path.dashboard);
                      return;
                    }
                    const temp = isAuthenticated
                      ? {
                          payment: item?.id,
                          havePhone: !!profile?.phone_number,
                        }
                      : "login";
                    setIsModalOpen(temp);
                  }}
                />
              );
            })}
          </div>
          <ContactBlock />
          <div className="flex flex-col items-center pt-10">
            <span className="text-xl sm:text-2xl font-bold text-center">
              {t("servicePackages.otherProducts.title")}
            </span>
            <span className="text-base sm:text-lg text-center">
              {t("servicePackages.otherProducts.subTitle")}
            </span>
          </div>
        </div>
        <div className="sm:px-8 px-4 flex gap-2 flex-col lg:flex-row w-full pb-10">
          <div className="flex-1 w-full flex gap-4 bg-white px-6 py-4 rounded-r-lg justify-center lg:justify-end">
            <img src={ggdrive} alt="ggdrive" />
            <div className="flex flex-col justify-between gap-2">
              <div className="flex flex-col">
                <span className="text-xl font-semibold">
                  {t("servicePackages.ggdrive.title")}
                </span>
                <span className="whitespace-pre-line text-sm">
                  {t("servicePackages.ggdrive.subTitle")}
                </span>
              </div>
              <a
                href="https://store.hoangphucphoto.com/san-pham/tai-khoan-luu-tru-google-drive-vinh-vien-500gb-1tb-2tb-5tb/"
                className="flex gap-2 text-primary"
              >
                <ChevronsRight className="w-4" />
                {t("servicePackages.ggdrive.btn")}
              </a>
            </div>
          </div>
          <div className="flex-1 w-full flex gap-4 bg-white px-6 py-4 rounded-l-lg justify-center lg:justify-start flex-row-reverse lg:flex-row">
            <img
              src={paneldarkroom}
              alt="paneldarkroom"
              className="object-contain w-24"
            />
            <div className="flex flex-col justify-between gap-2 items-end lg:items-start">
              <div className="flex flex-col">
                <span className="text-xl font-semibold">
                  {t("servicePackages.paneldarkroom.title")}
                </span>
                <span className="whitespace-pre-line text-sm">
                  {t("servicePackages.paneldarkroom.subTitle")}
                </span>
              </div>
              <a
                href="https://store.hoangphucphoto.com/darkroom/"
                className="flex gap-2 text-primary"
              >
                <ChevronsRight className="w-4" />
                {t("servicePackages.paneldarkroom.btn")}
              </a>
            </div>
          </div>
        </div>
        <div className="max-w-[1440px] m-auto sm:px-8 px-4 relative py-10 bg-gray-100 flex flex-col items-center w-full">
          <span className="text-base sm:text-lg">
            {t("servicePackages.explore.subTitle")}
          </span>
          <span className="text-xl sm:text-2xl font-semibold">
            {t("servicePackages.explore.title")}
          </span>
          <div
            dangerouslySetInnerHTML={{
              __html: t("servicePackages.explore.content"),
            }}
            className="py-4 text-xs sm:text-base text-center"
          ></div>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            type="primary"
          >
            {t("servicePackages.explore.btn")}
          </Button>
        </div>
        <Footer />
      </div>
      <UpdatePhone
        isVisible={
          typeof isModalOpen === "object" && !isModalOpen.havePhone
            ? isModalOpen
            : ""
        }
        onClick={(value) => {
          console.log(value);
          setIsModalOpen(!!value ? value : undefined);
        }}
      />
      <MessageModal
        isVisible={typeof isModalOpen === "string"}
        onClose={() => setIsModalOpen(undefined)}
        title={t("servicePackages.confirmToLogin.title")}
        type="confirm"
        btnText={t("servicePackages.confirmToLogin.btn")}
        handleClick={() => {
          navigate(path.login);
        }}
      />
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("servicePackages.payment.title")}
          </div>
        }
        open={typeof isModalOpen === "object" && isModalOpen.havePhone}
        onCancel={() => {
          setIsModalOpen(undefined);
        }}
        footer={null}
      >
        <ModalPayment
          values={
            typeof isModalOpen === "object"
              ? getPackageFromIdPrice(
                  isModalOpen?.payment,
                  membershipPlans?.data
                )?.membership_plan_price
              : []
          }
        />
      </Modal>
    </>
  );
};

export default ServicePackages;

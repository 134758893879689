import { useMutation, useQuery } from "react-query";
import { queryKeys } from "../../constants/queryKeys";
import {
  TCalculateUpgrade,
  TCreateSubmissions,
  TCreateSubmissionsResponse,
  TCreateTransaction,
  TDiscount,
  TMembershipPlansResponse,
  TTransactionResponse,
} from "../../types/membership.type";
import { paymentEndpoint } from "../endpoints";
import http from "../http.service";
export const useSubcriptionCreateApi = () => {
  const mutation = useMutation({
    mutationFn: async (dataQuery: TCreateSubmissions) => {
      return await http.post<TCreateSubmissionsResponse>(
        paymentEndpoint.CREATE_SUBCRIPTION,
        dataQuery
      );
    },
  });
  return mutation;
};

export const paymentPaypalCreate = (data: any) => {
  return http.post(paymentEndpoint.PAYMENT_PAYPAL_CREATE, data);
};
export const paymentPaypalCheck = (data: any) => {
  return http.post(paymentEndpoint.PAYMENT_PAYPAL_CHECK, data);
};

export const paymentNganluongCreate = (data: any) => {
  return http.post(paymentEndpoint.PAYMENT_NGAN_LUONG_CREATE, data);
};

export const paymentNganluongCheck = (data: any) => {
  return http.post(paymentEndpoint.PAYMENT_NGAN_LUONG_CHECK, data);
};

export const paymentSePayCreate = (data: any) => {
  return http.post(paymentEndpoint.PAYMENT_SEPAY_CREATE, data);
};
export const useSePayCreateApi = () => {
  const mutation = useMutation({
    mutationFn: async (dataQuery: TCreateTransaction) => {
      return await http.post<TTransactionResponse>(
        paymentEndpoint.PAYMENT_SEPAY_CREATE,
        dataQuery
      );
    },
  });
  return mutation;
};

export const paymentSePayCheck = (data: any) => {
  return http.post(paymentEndpoint.PAYMENT_SEPAY_CHECK, data);
};

export const useGetMembershipPlansApi = () => {
  const query = useQuery([queryKeys.GET_MEMBERSHIP_PLANS], async () => {
    const res = await http.get<TMembershipPlansResponse>(
      paymentEndpoint?.MEMBERSHIP_PLANS
    );
    return res;
  });

  return query;
};

export const useGetDiscountApi = (promoCode: string) => {
  const query = useQuery(
    [queryKeys.GET_DISCOUNT],
    async () => {
      const res = await http.get<TDiscount>(
        paymentEndpoint?.CHECK_DISCOUNT + "/" + promoCode
      );
      return res;
    },
    { enabled: false, retry: 1 }
  );

  return query;
};

export const useCalculateUpgradeApi = () => {
  const query = useQuery([queryKeys.GET_CALCULATE_UPGRADE], async () => {
    const res = await http.get<TCalculateUpgrade>(
      paymentEndpoint?.UPGRADE_PACKAGE
    );
    return res;
  });

  return query;
};

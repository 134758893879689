import { Button, Radio, Select } from "antd";
import { useState } from "react";
import { Download, Tag } from "react-feather";
import { useTranslation } from "react-i18next";
import { DOWNLOAD_PHOTOS } from "../../constants/album";
import { useGetAlbumApi } from "../../services/apis/album.api";
import { IDownloadAlbum } from "../../types/album.type";
import { zipDownLoader } from "../../utils/common";

const DownloadPhotos = (props: IDownloadAlbum) => {
  const { id, status, tags, totalPhotos } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState<`${DOWNLOAD_PHOTOS}`>(DOWNLOAD_PHOTOS.ALL);
  const [currentTags, setCurrentTags] = useState<string[]>([]);
  const [loadingDown, setLoadingDown] = useState(false);
  const options = { [value]: value !== DOWNLOAD_PHOTOS.TAG ? 1 : currentTags };
  const { isLoading, refetch } = useGetAlbumApi({
    perPage: totalPhotos,
    albumIdProps: id,
    filterParams: value !== DOWNLOAD_PHOTOS.ALL && options,
    enabled: false,
  });
  const disableBtn =
    isLoading ||
    loadingDown ||
    (value === "tag_ids" && currentTags.length === 0);
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const onSubmit = async () => {
    setLoadingDown(true);
    const { data: newData } = await refetch();
    await zipDownLoader({
      images: newData?.pages[0]?.data?.data?.files?.data,
      name: newData?.pages[0]?.data?.data.name,
    });
    setLoadingDown(false);
  };

  return (
    <div className="flex flex-col">
      <span className="font-medium">
        {t("dashboard.albums.action.download.photos.title")}
      </span>
      <Radio.Group
        name="download-photos"
        className="flex flex-col gap-1"
        onChange={handleChange}
        value={value}
      >
        {Object.values(DOWNLOAD_PHOTOS).map((item) => {
          const disable = !status[item] && item !== DOWNLOAD_PHOTOS.ALL;
          return (
            <Radio
              value={item}
              key={item}
              disabled={disable}
              className={`${disable ? "ml-4" : ""}`}
            >
              <span className="text-nowrap">
                {t(`dashboard.albums.action.download.photos.${item}`)}
              </span>
            </Radio>
          );
        })}
      </Radio.Group>
      {status.tag_ids && (
        <div className="sm:w-11/12 ml-6 w-10/12 flex flex-col gap-1 mt-1">
          <Select
            mode="multiple"
            value={currentTags}
            onChange={setCurrentTags}
            suffixIcon={<Tag className="w-4" />}
            placeholder={t("dashboard.albums.action.download.photos.chooseTag")}
            options={tags?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
          {value === DOWNLOAD_PHOTOS.TAG && currentTags.length === 0 && (
            <span className="text-xs block text-red-500">
              {t("dashboard.albums.action.download.photos.minTag")}
            </span>
          )}
        </div>
      )}
      <Button
        type="default"
        htmlType="submit"
        className={`my-4 transition-all success ${
          disableBtn ? "opacity-70" : ""
        }`}
        disabled={disableBtn}
        loading={loadingDown}
        onClick={() => onSubmit()}
      >
        {!loadingDown && <Download className="w-4" />}
        {t("dashboard.albums.action.download.photos.btn")}
      </Button>
      {loadingDown && (
        <span className="text-center text-orange-600 whitespace-pre-line">
          {t("dashboard.albums.action.download.photos.loading")}
        </span>
      )}
    </div>
  );
};

export default DownloadPhotos;

import { Modal, Skeleton, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Download } from "react-feather";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import AlbumGallery from "../../components/AlbumGallery";
import BannerAlbumClient from "../../components/Albums/BannerClient";
import DownloadPhotos from "../../components/Albums/DownloadPhotos";
import FilterAlbum from "../../components/Albums/FilterAlbum";
import FolderAlbum from "../../components/FolderAlbum";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import MetaTags from "../../components/MetaTags";
import { ACTION_ALBUM, PATH_ALBUM } from "../../constants/album";
import { useGetAlbumPublicApi } from "../../services/apis/album.api";
import { TAlbumDetail, TDownloadAlbum } from "../../types/album.type";

const PublicAlbum = () => {
  const { albumId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [foldersId, setFoldersId] = useState<number[]>(undefined);
  const [isFilter, setIsFilter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState<`${ACTION_ALBUM}`>(null);
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetAlbumPublicApi({
      foldersId: foldersId,
      filterParams: { ...isFilter },
    });
  const album = data?.pages[0]?.data?.data;
  const cachedAlbum: Partial<TAlbumDetail> =
    data?.pages[data.pages?.length === 1 ? 0 : 1]?.data?.data ||
    queryClient.getQueryData([`album_detail${albumId}`]);
  const statusDownload: Record<TDownloadAlbum, boolean> = {
    is_liked: !!cachedAlbum?.like_count,
    tag_ids: cachedAlbum?.tags?.length > 0,
    is_commented: !!cachedAlbum?.comment_count,
    is_recommended: !!cachedAlbum?.recommend_count,
  };
  const { ref, inView } = useInView();
  const images = useMemo(() => {
    return data?.pages.reduce((acc, page) => {
      return [...acc, ...page?.data?.data?.files?.data];
    }, []);
  }, [data]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return !cachedAlbum?.id ? (
    <div className="w-full overflow-hidden mt-4">
      <LoadingPage />
    </div>
  ) : (
    <div className="scroller flex flex-col w-full">
      <BannerAlbumClient
        customer_name={cachedAlbum?.customer_name}
        name={cachedAlbum?.name}
        thumbnail_url={cachedAlbum?.thumbnail_url}
      />
      <div
        id="album-body"
        className="scroller-item w-full bg-white z-10 scroll-ruler overflow-y-auto sm:px-8 px-4"
      >
        <div className="flex sm:flex-row flex-col justify-between items-center py-2 lg:py-4 border-b gap-1">
          <FilterAlbum
            setIsFilter={(values) => {
              setIsFilter(values);
            }}
            counts={{
              all: cachedAlbum?.file_count,
              comment: cachedAlbum?.comment_count,
              like: cachedAlbum?.like_count,
              recommend: cachedAlbum?.recommend_count,
            }}
          />
          <div className="flex sm:space-x-2 space-x-1 text-sm items-center flex-wrap sm:flex-nowrap">
            <button
              disabled={
                !cachedAlbum?.is_downloadable ||
                (cachedAlbum?.is_downloadable && cachedAlbum?.file_count === 0)
              }
              className={`${
                !cachedAlbum?.is_downloadable ||
                (cachedAlbum?.is_downloadable && cachedAlbum?.file_count === 0)
                  ? "bg-gray-200/50 opacity-60 cursor-not-allowed"
                  : "cursor-pointer"
              } py-1 px-3 flex gap-1 items-center transition-all hover:bg-gray-200/50 rounded-3xl`}
              onClick={() => {
                setIsModalOpen(ACTION_ALBUM.DOWNLOAD_PHOTOS);
              }}
            >
              <Download className="w-4" />
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <FolderAlbum
            folders={cachedAlbum?.folders}
            onClick={(data) => {
              setFoldersId(data);
            }}
            loadingAlbum={isLoading}
          />
        </div>
        <div className="py-4">
          {isLoading ? (
            <div className="w-full overflow-hidden">
              <Skeleton active />
            </div>
          ) : (
            <AlbumGallery
              images={images || []}
              fetchNextPage={fetchNextPage}
              checkedPath={PATH_ALBUM?.SHOW}
              albumDetail={album}
              isDownloadable={cachedAlbum?.is_downloadable}
            />
          )}
          <div ref={ref}>
            {isFetchingNextPage ? (
              <div className="py-6 w-full flex justify-center fixed bottom-0 left-1/2 -translate-x-1/2 bg-gradient-to-t from-white to-transparent">
                <Spin size="large" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("dashboard.albums.action.download.modalDownload.title")}
          </div>
        }
        open={isModalOpen?.includes(ACTION_ALBUM.DOWNLOAD_PHOTOS)}
        onCancel={() => {
          setIsModalOpen(null);
        }}
        footer={null}
      >
        <DownloadPhotos
          id={cachedAlbum?.id}
          tags={cachedAlbum?.tags}
          status={statusDownload}
          totalPhotos={cachedAlbum?.file_count}
        />
      </Modal>
      <MetaTags
        title={album?.name}
        image={album?.thumbnail_url || ""}
        name="Shotpik"
      />
    </div>
  );
};

export default PublicAlbum;

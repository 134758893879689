import { Button, Form, Input, Modal, Skeleton } from "antd";
import { useWatch } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { saveAs } from "file-saver";
import PhotoSwipeDynamicCaption from "photoswipe-dynamic-caption-plugin";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { useEffect, useMemo, useState } from "react";
import { Download, Edit3, MessageSquare, Save, Tag } from "react-feather";
import { useTranslation } from "react-i18next";
import { FaRegStar, FaStar } from "react-icons/fa";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  useCommentApi,
  useLikedApi,
  useRecommendApi,
  useTagApi,
} from "../../services/apis/file.api";
import { TAlbumDetail, TImage } from "../../types/album.type";
import MessageModal from "../MessageModal";
import {
  addEventTag,
  getLikeAnoButton,
  getRecommendedUserButton,
  getTagDeleteButton,
  getTagModal,
} from "./PhotoActions";

import { LoadingOutlined } from "@ant-design/icons";
import HTMLElementWithEvent from "./HTMLElementWithEvent";
import { ACTION_ALBUM, PATH_ALBUM } from "../../constants/album";
import { isIOS } from "../../utils/common";

declare global {
  interface Window {
    pswp: any;
    passComment: string;
    lightbox: PhotoSwipeLightbox;
    clickImage: any;
    fetchNextPage: (options?: any) => Promise<any>;
    isFetchingNextPage: boolean;
  }
}
const AlbumGallery = ({
  images,
  albumDetail,
  fetchNextPage,
  checkedPath,
  isCommentPassword,
  isDownloadable,
  maxFileLikes,
}: {
  images: TImage[];
  albumDetail: TAlbumDetail;
  fetchNextPage: (options?: any) => Promise<any>;
  checkedPath: string;
  isCommentPassword?: boolean;
  isDownloadable?: boolean;
  maxFileLikes?: number;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { mutate: mutateRecommend } = useRecommendApi();
  const { mutate: mutateLiked } = useLikedApi();
  const { mutate: mutateComment, isLoading: loadingComment } = useCommentApi();
  const { mutate: mutateTag } = useTagApi();
  const [errorPassComment, setErrorPassComment] = useState<string>("");
  const [passComment, setPassComment] = useState<string>(null);
  const comment = useWatch("comment", form);
  const commentPass = useWatch("comment_password", form);
  const [isModalOpen, setIsModalOpen] = useState<ACTION_ALBUM>(null);
  const columnsCountBreakPoints = {
    default: 4,
    768: 3,
    640: 2,
  };
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<{
    id: number;
  }>(null);
  const [isError, setIsError] = useState<"like">(undefined);
  const [loaded, setLoaded] = useState(new Array(images.length).fill(false));
  const [tagShow, setTagShow] = useState<string[]>([]);

  const handleImageLoad = (index: number) => {
    if (loaded[index]) return;
    setLoaded((prevLoaded) => {
      const newLoaded = [...prevLoaded];
      newLoaded[index] = true;
      return newLoaded;
    });
  };

  const onFinish = (values: any) => {
    mutateComment(
      {
        id: images[isCommentModalOpen.id].id,
        dataQuery: {
          comment: values?.comment,
          comment_password: values?.comment_password,
        },
      },
      {
        onSuccess: () => {
          document
            .querySelectorAll("#comment-" + isCommentModalOpen.id)
            .forEach((element: any) => {
              element.innerHTML = values?.comment;
              const closestCommentWrap = (element as HTMLElement).closest(
                ".comment-wrap"
              );
              if (closestCommentWrap && !!values?.comment) {
                (closestCommentWrap as HTMLElement).style.backgroundColor =
                  "#414141";
              }
            });

          images[isCommentModalOpen.id].comments = [
            {
              ...images[isCommentModalOpen.id].comments[0],
              comment: values?.comment,
            },
          ];
          if (passComment !== values.comment_password) {
            setPassComment(values.comment_password);
            window.passComment = values.comment_password;
          }
          setIsCommentModalOpen(null);
        },
        onError: () => {
          setErrorPassComment(t("formConfimPassAlbum.confirmFail"));
        },
      }
    );
  };

  useEffect(() => {
    if (errorPassComment !== "") setErrorPassComment("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, commentPass]);

  useEffect(() => {
    window.fetchNextPage = fetchNextPage;
  }, [fetchNextPage]);

  const recommendF = (index, e) => {
    const dom = document.getElementById("recommend-" + index);
    dom.click();
  };
  const likeF = (index, e) => {
    const dom = Array.from(
      document.querySelectorAll("#like-" + index)
    ).pop() as HTMLElement;
    dom.click();
  };

  const commentF = (index) => {
    const comment = document.getElementById("comment-" + index)?.textContent;
    form.setFieldValue("comment", comment || "");

    form.setFieldValue("comment_password", window.passComment || "");
    setIsCommentModalOpen({
      id: index,
    });
  };

  const toggleTag = (index, tagId) => {
    if (albumDetail.is_locked) {
      setIsModalOpen(ACTION_ALBUM.LOCK);
      return;
    }
    mutateTag(
      {
        id: images[index].id,
        tagId: tagId,
      },
      {
        onSuccess: (response) => {
          images[index].tags = response.data.data;
          document
            .querySelector(".pswp__dynamic-caption .tag-wrap-" + index)
            .replaceChildren();
          images[index].tags.forEach((tag) => {
            document
              .querySelector(".pswp__dynamic-caption .tag-wrap-" + index)
              .insertAdjacentElement(
                "beforeend",
                getTagDeleteButton(
                  checkedPath.includes(PATH_ALBUM.DASHBOARD),
                  index,
                  tag,
                  (index, id) => toggleTag(index, id)
                )
              );
          });
        },
      }
    );
  };

  const lightbox = useMemo(() => {
    
    const lb = new PhotoSwipeLightbox({
      gallery: document.getElementById("gallery"),
      children: Array.from(
        document.querySelectorAll(".pswp-gallery__item")
      ).sort((a, b) => parseInt(a.id) - parseInt(b.id)) as HTMLElement[],
      loop: false,
      showHideAnimationType: "fade",
      mainClass: "text-xs lg:text-base",
      arrowPrev: true,
      arrowNext: true,
      imageClickAction: (releasePoint, e) => {
        if (window.clickImage) return likeF(window.pswp.currIndex, e);
        window.clickImage = true;
        setTimeout(() => {
          window.clickImage = false;
        }, 200);
      },
      bgClickAction: false,
      doubleTapAction: (releasePoint, e) => {
        likeF(window.pswp.currIndex, e);
      },
      zoom: false,
      pswpModule: () => import("photoswipe"),
    });

    !checkedPath.includes(PATH_ALBUM.SHOW) &&
      lb.on("uiRegister", function () {
        lb.pswp.ui.registerElement({
          name: "recommended-button",
          className: "pswp__recommended-button cursor-pointer",
          order: 9,
          html: "<div></div>",
          onInit: (el, pswp) => {
            pswp.on("change", () => {
              const isRecommended = images[pswp.currIndex]?.is_recommended;
              el.innerHTML = getRecommendedUserButton(
                isRecommended,
                checkedPath.includes(PATH_ALBUM.DASHBOARD),
                t("albumDetail.album.isRecommended")
              );

              el.id = "recommend-sl-" + pswp.currIndex;

              el.addEventListener("click", (e) => {
                checkedPath.includes(PATH_ALBUM.DASHBOARD) &&
                  recommendF(pswp.currIndex, e);
              });
            });
          },
        });
      });
    lb.on("change", () => {
      const pswp = lb.pswp;
      const childrenCount =
        pswp.options.children instanceof Array
          ? pswp.options.children.length
          : 0;

      if (pswp.currIndex + 12 > childrenCount) {
        // Only fetch next page if not already fetching
        if (!window.isFetchingNextPage) {
          window.isFetchingNextPage = true;
          fetchNextPage().finally(() => {
            window.isFetchingNextPage = false;
          });
        }
      }
      const p = (pswp as any).currSlide.dynamicCaption.element as HTMLElement;

      if (!p) {
        return;
      }
      //Handle liked photo
      const btnLiked = p.querySelector("#like-sl-" + pswp.currIndex);
      if (btnLiked) {
        btnLiked.addEventListener("click", (e) => {
          likeF(pswp.currIndex, e);
        });
      }

      //Hanlde download photo
      const btnDownload = p.querySelector(".download") as HTMLAnchorElement;
      if (btnDownload) {
        btnDownload.onclick = (event: MouseEvent): void => {
          event.preventDefault();
          const fileUrl =
            new URL(btnDownload.href).origin +
            new URL(btnDownload.href).pathname;
          const fileName = btnDownload.getAttribute("data-file_name");
          const loaderIcon = btnDownload.querySelector(".loader-icon-file");
          const downloadIcon = btnDownload.querySelector(".download-icon-file");

          downloadIcon.classList.toggle("hidden");
          loaderIcon.classList.toggle("hidden");
          (event.target as HTMLAnchorElement).style.pointerEvents = "none";
          fetch(fileUrl)
            .then((response) => response.blob())
            .then(async (blob) => {
              if (!isIOS()) {
                const url = window.URL.createObjectURL(blob);
                saveAs(url, fileName);
                window.URL.revokeObjectURL(url);
              } else {
                const file = new File([blob], fileName, { type: blob.type });

                if (
                  navigator.canShare &&
                  navigator.canShare({ files: [file] })
                ) {
                  await navigator.share({
                    files: [file],
                    title: "Lưu ảnh",
                    text: "Nhấn để lưu ảnh vào Photos",
                  });
                } else {
                  alert("Trình duyệt không hỗ trợ chia sẻ ảnh.");
                }
              }
            })
            .catch((error) => {
              console.error("Error downloading file:", error);
            })
            .finally(() => {
              downloadIcon.classList.toggle("hidden");
              loaderIcon.classList.toggle("hidden");
              (event.target as HTMLAnchorElement).style.pointerEvents = "auto";
            });
        };
      }

      //Handle commented
      const editComment = p.querySelectorAll(".edit-comment");
      editComment &&
        editComment.forEach((item, i) => {
          item.addEventListener("click", () => {
            commentF(pswp.currIndex);
          });
        });

      const addTag = p.querySelector(".pswp__dynamic-caption .add-tag");
      addTag &&
        addTag.addEventListener("click", () => {
          let tagModal = document.getElementById("dropdownTag");
          if (tagModal) {
            tagModal.remove();
          } else {
            const tagModal = getTagModal(
              albumDetail.tags,
              images[pswp.currIndex]?.tags || [],
              (id) => toggleTag(pswp.currIndex, id)
            );
            document
              .querySelector(".pswp__dynamic-caption #tag-" + pswp.currIndex)
              .insertAdjacentElement("beforeend", tagModal);
          }
        });

      if (checkedPath.includes(PATH_ALBUM.ANO)) {
        const wrapTag = p.querySelector(".pswp__dynamic-caption .tag-wrap");
        wrapTag && addEventTag(wrapTag, (index, id) => toggleTag(index, id));
      }
    });

    new PhotoSwipeDynamicCaption(lb, {
      mobileLayoutBreakpoint: 700,
      type: "below",
      mobileCaptionOverlapRatio: 1,
    });
    lb.addFilter("numItems", (number, dataSource) => {
      return albumDetail.file_count;
    });
    lb.init();

    window.lightbox = lb;

    return lb;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    lightbox.options.children = Array.from(
      document.querySelectorAll(".pswp-gallery__item")
    ).sort((a, b) => parseInt(a.id) - parseInt(b.id)) as HTMLElement[];

    lightbox.options.gallery = document.getElementById("gallery");

    lightbox.options.dataSource = lightbox.options.children;
    if (window.pswp?.options?.dataSource) {
      window.pswp.options.dataSource = lightbox.options.dataSource;
      window.pswp.options.children = lightbox.options.children;
    }

    lightbox.init();
    // return () => {
    //   console.log('destroy');
    //   lightbox.destroy();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (!window.pswp) {
      return;
    }
    if (isCommentModalOpen) {
      window.pswp.events.removeAll();
    } else {
      window.pswp.updateSize();
      if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
        setTimeout(() => {
          window.pswp.updateSize();
        }, 500);
      }
      window.pswp.setScrollOffset(0, window.pageYOffset);
      window.pswp.dispatch("bindEvents");
    }
  }, [isCommentModalOpen]);

  return (
    <div id="gallery">
      <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
        <Masonry gutter="8px">
          {images.map((image, i) => {
            const elePhoto = document.getElementById(`photo${image?.id}`);
            return (
              <div key={image?.id}>
                <div key={i} className="relative">
                  <div className="pswp-gallery__item" id={i.toString()}>
                    <a
                      href={image?.url}
                      data-thumb-src={image?.url}
                      data-pswp-width={window.innerWidth}
                      data-pswp-height={
                        elePhoto
                          ? (elePhoto.clientHeight / elePhoto.clientWidth) *
                            window.innerWidth
                          : "auto"
                      }
                      rel="noreferrer"
                    >
                      <div className="relative w-full">
                        {!loaded[i] && <Skeleton active />}
                        <img
                          id={`photo${image?.id}`}
                          src={image?.url}
                          className="block w-full"
                          alt={image?.file_name}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          onLoad={() => handleImageLoad(i)}
                        />
                        {tagShow.includes(image.id) && (
                          <div className="absolute top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center">
                            <div className="flex w-max flex-wrap min-w-32 max-w-64 items-center justify-center gap-1">
                              {image.tags.map((tag, index) => (
                                <span
                                  className="tag-item max-w-32 whitespace-nowrap overflow-hidden text-ellipsis ml-1 text-xs px-2 py-1 rounded-lg bg-secondary opacity-80 hover:bg-secondary hover:opacity-100 transition-all"
                                  key={index}
                                >
                                  {tag.name}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </a>
                    <div className="pswp-caption-content hidden w-full mx-4">
                      <div
                        key={i}
                        className="flex sm:flex-row flex-col justify-center sm:justify-between items-center m-5"
                      >
                        <div className="w-full lg:w-64">{image?.file_name}</div>
                        {!checkedPath.includes(PATH_ALBUM.SHOW) && (
                          <div className="comment-wrap flex gap-1 flex-col w-full sm:w-auto flex-1">
                            <div
                              className={`comment text-xs ${
                                image?.comments[0]?.comment
                                  ? "rounded-md bg-[#414141] text-yellow-50 hover:bg-[#414141] py-1 px-1 lg:py-2 lg:px-3"
                                  : ""
                              }`}
                              id={`comment-${i}`}
                            >
                              {image?.comments[0]?.comment}
                            </div>
                            <div
                              className={`tag text-xs tag-wrap tag-wrap-${i}`}
                            >
                              {image?.tags?.map((tag, index) => (
                                <HTMLElementWithEvent
                                  element={getTagDeleteButton(
                                    checkedPath.includes(PATH_ALBUM.DASHBOARD),
                                    i,
                                    tag,
                                    (index, id) => toggleTag(index, id)
                                  )}
                                  key={index}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        <div className="justify-self-end flex gap-2 items-center justify-end w-full lg:w-64">
                          {checkedPath.includes(PATH_ALBUM.ANO) &&
                            isCommentPassword && (
                              <div className="w-10 h-10 flex gap-1 justify-center items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-full edit-comment">
                                <Edit3 className="w-5" />
                              </div>
                            )}
                          {checkedPath.includes(PATH_ALBUM.ANO) &&
                            albumDetail.tags?.length > 0 && (
                              <div className="relative" id={`tag-${i}`}>
                                <div className="w-10 h-10 flex gap-1 justify-center items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-full add-tag">
                                  <Tag className="w-5" />
                                </div>
                              </div>
                            )}
                          {!checkedPath.includes(PATH_ALBUM.SHOW) && (
                            <div
                              id={`like-sl-${i}`}
                              dangerouslySetInnerHTML={{
                                __html: getLikeAnoButton(
                                  image?.is_liked,
                                  checkedPath.includes(PATH_ALBUM.DASHBOARD)
                                ),
                              }}
                            />
                          )}

                          {isDownloadable && (
                            <a
                              href={image?.url}
                              target="_blank"
                              data-file_name={image.file_name}
                              rel="noreferrer"
                              className="w-10 h-10 flex gap-1 justify-center items-center cursor-pointer transition-all hover:bg-gray-200/40 rounded-full download"
                            >
                              <div className="relative">
                                <Download className="w-5 download-icon-file" />
                                <LoadingOutlined className="hidden loader-icon-file" />
                              </div>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {loaded[i] && !checkedPath.includes(PATH_ALBUM.SHOW) && (
                    <>
                      <div
                        id={`recommend-${i}`}
                        className={`p-2 absolute top-1 left-1 ${
                          checkedPath.includes(PATH_ALBUM.DASHBOARD)
                            ? "cursor-pointer transition-all hover:bg-gray-200/40 rounded-full"
                            : "cursor-default"
                        } ${
                          checkedPath.includes(PATH_ALBUM.ANO) &&
                          !image?.is_recommended
                            ? "hidden"
                            : ""
                        }`}
                        onClick={() => {
                          if (checkedPath.includes(PATH_ALBUM.DASHBOARD)) {
                            if (albumDetail.is_locked) {
                              setIsModalOpen(ACTION_ALBUM.LOCK);
                              return;
                            }
                            mutateRecommend(image.id, {
                              onSuccess: () => {
                                image.is_recommended = image.is_recommended
                                  ? 0
                                  : 1;

                                const rcm = document.getElementById(
                                  "recommend-sl-" + i
                                );
                                if (rcm) {
                                  rcm.closest(
                                    ".pswp__recommended-button"
                                  ).innerHTML = getRecommendedUserButton(
                                    image.is_recommended,
                                    checkedPath.includes(PATH_ALBUM.DASHBOARD),
                                    t("albumDetail.album.isRecommended")
                                  );
                                }
                              },
                            });
                          }
                        }}
                      >
                        {!!image?.is_recommended ? (
                          <FaStar className="text-secondary text-xl opacity-90" />
                        ) : (
                          <FaRegStar className="text-gray-600 text-xl opacity-90" />
                        )}
                      </div>
                      <div
                        id={`like-${i}`}
                        className={`p-2 absolute top-1 right-1 ${
                          checkedPath.includes(PATH_ALBUM.DASHBOARD) &&
                          !image?.is_liked
                            ? "hidden"
                            : "rounded-full transition-all hover:bg-gray-200/40"
                        }`}
                        onClick={(e) => {
                          if (checkedPath.includes(PATH_ALBUM.ANO)) {
                            if (albumDetail.is_locked) {
                              setIsModalOpen(ACTION_ALBUM.LOCK);
                              return;
                            }
                            image.is_liked = image.is_liked ? 0 : 1;
                            const like = document.querySelectorAll(
                              "#like-sl-" + i
                            );
                            if (like) {
                              for (let key = 0; key < like.length; key++) {
                                like[key].innerHTML = getLikeAnoButton(
                                  image.is_liked,
                                  checkedPath.includes(PATH_ALBUM.DASHBOARD)
                                );
                                // like[key].addEventListener("click", (e) => {
                                //   likeF(i, e);
                                // });
                              }
                            }
                            mutateLiked(image.id, {
                              onSuccess: () => {},
                              onError: (error) => {
                                image.is_liked = image.is_liked ? 0 : 1;
                                if (like) {
                                  for (let key = 0; key < like.length; key++) {
                                    like[key].innerHTML = getLikeAnoButton(
                                      image.is_liked,
                                      checkedPath.includes(PATH_ALBUM.DASHBOARD)
                                    );
                                    // like[key].addEventListener("click", (e) => {
                                    //   likeF(i, e);
                                    // });
                                  }
                                }
                                if (
                                  error.response?.data?.message ===
                                  "CANNOT_LIKE_FILE"
                                )
                                  setIsError("like");
                                if (
                                  error.response?.data?.message ===
                                  "ALBUM_IS_LOCKED"
                                )
                                  setIsModalOpen(ACTION_ALBUM.LOCK);
                              },
                            });
                          }
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getLikeAnoButton(
                              image?.is_liked,
                              checkedPath.includes(PATH_ALBUM.DASHBOARD)
                            ),
                          }}
                        ></div>
                      </div>
                      <div
                        className={`p-2 absolute bottom-0 right-0 ${
                          !image?.comments[0]?.comment ? "hidden" : ""
                        }`}
                      >
                        <MessageSquare className="text-secondary text-xl" />
                      </div>
                      <div
                        className={`p-2 absolute bottom-0 left-0 cursor-pointer transition-all hover:bg-gray-200/40 rounded-full ${
                          !image?.tags?.length ? "hidden" : ""
                        }`}
                        onClick={() =>
                          tagShow.includes(image.id)
                            ? setTagShow(
                                tagShow.filter((id) => id !== image.id)
                              )
                            : setTagShow([...tagShow, image.id])
                        }
                      >
                        <Tag height={22} className="text-secondary text-xl" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("albumDetail.album.titleComment")}
          </div>
        }
        open={!!isCommentModalOpen}
        onCancel={() => {
          setIsCommentModalOpen(null);
          form.resetFields();
        }}
        footer={null}
        className="photo-gallery"
      >
        {errorPassComment && (
          <div className="text-sm text-primary mr-auto">{errorPassComment}</div>
        )}
        <div className="flex flex-col gap-5">
          <Form
            form={form}
            name="comment-photo-form"
            layout="vertical"
            onFinish={onFinish}
            colon={false}
          >
            <Form.Item
              name="comment"
              label={t("albumDetail.album.labelComment")}
            >
              <TextArea
                autoSize
                autoFocus
                onFocus={(e) => {
                  e.target.focus();
                }}
                maxLength={400}
              />
            </Form.Item>
            <Form.Item
              name="comment_password"
              className={`mb-0 relative ${passComment ? "hidden" : ""}`}
              label={t("albumDetail.album.commentPassword")}
              rules={[
                {
                  required: true,
                  message: (
                    <span className="text-xs absolute">
                      {t("formCreateAlbum.requirePass")}
                    </span>
                  ),
                },
                {
                  min: 6,
                  message: (
                    <span className="text-xs mb-1 block">
                      {t("formLogin.minPassword")}
                    </span>
                  ),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={`submit-comment success transition-all flex gap-2 items-center w-fit ml-auto mt-4`}
              disabled={loadingComment}
              loading={loadingComment}
              onClick={() => {}}
            >
              <Save className="w-4" />
              {t("albumDetail.album.saveComment")}
            </Button>
          </Form>
        </div>
      </Modal>
      <MessageModal
        isVisible={isError === "like"}
        onClose={() => {
          setIsError(undefined);
        }}
        title={t("albumDetail.album.limitLike")}
        subTitle={t("albumDetail.album.limitLikeSub", { photo: maxFileLikes })}
        type="error"
        btnText={t("albumDetail.album.btnClose")}
      />
      <MessageModal
        title={t("albumDetail.album.titleLock")}
        isVisible={!!isModalOpen}
        onClose={() => setIsModalOpen(null)}
        subTitle={t("albumDetail.album.subTitleLock")}
        type="error"
        btnText={t("albumDetail.album.btnClose")}
      />
    </div>
  );
};

export default AlbumGallery;

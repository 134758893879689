import { Check, X } from "react-feather";
import { TServicePackage } from "../../types/membership.type";

const Package = ({
  service,
  onClick,
}: {
  service: TServicePackage;
  onClick?: () => void;
}) => {
  const {
    name,
    price,
    priceUnit,
    btn,
    benefits,
    classNameCard,
    classNameBtn,
    disable,
  } = service;

  return (
    <div
      className={`bg-gray-100 text-black py-9 px-4 flex flex-col gap-5 text-center items-center ${
        classNameCard ? classNameCard : ""
      }`}
    >
      <div className="text-3xl font-semibold">{name}</div>
      <div className="flex justify-center">
        <span className="text-3xl font-semibold">
          {price?.toLocaleString("de-DE")}
        </span>
        <span className="text-sm">{priceUnit}</span>
      </div>
      <div className="flex flex-col gap-3">
        {Object.entries(benefits).map(([key, benefit]) => {
          if (benefit?.active === undefined)
            return (
              <div key={key} className="flex items-center gap-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: benefit?.title,
                  }}
                  className="ml-6 text-left"
                />
              </div>
            );
          return (
            <div key={key} className="flex items-center gap-2">
              {benefit?.active ? (
                <Check className="w-4 text-secondary" />
              ) : (
                <X className="w-4 text-primary" />
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: benefit?.title,
                }}
                className={`${
                  !benefit?.active ? "text-gray-500" : ""
                } text-left flex-1`}
              />
            </div>
          );
        })}
      </div>
      <button
        onClick={() => {
          onClick && onClick();
        }}
        disabled={disable}
        className={`mt-auto border border-primary text-primary py-[6px] w-36 rounded-lg ${
          classNameBtn ? classNameBtn : ""
        } ${
          disable
            ? "cursor-default opacity-40"
            : "cursor-pointer hover:bg-red-100 transition-all hover:opacity-90"
        }`}
      >
        {btn}
      </button>
    </div>
  );
};

export default Package;

import { useTranslation } from "react-i18next";
import { TServicePackage } from "../types/membership.type";

export const useServicePackages: () => TServicePackage[] = () => {
  const { t } = useTranslation();
  return [
    {
      id: 1,
      key: "Free",
      priceUnit: t("servicePackages.packageFree.priceUnit"),
      btn: t("servicePackages.packageFree.btn"),
      benefits: {
        keyFeature: { title: t("servicePackages.intro.keyFeature") },
        albumPerMonth: {
          active: true,
          title: t("servicePackages.packageFree.albumPerMonth"),
        },
        timeLife: {
          active: true,
          title: t("servicePackages.packageFree.timeLife"),
        },
        // faceFinder: {
        //   active: false,
        //   title: t('servicePackages.packageFree.faceFinder'),
        // },
        downloadPhoto: {
          active: false,
          title: t("servicePackages.packageFree.downloadPhoto"),
        },
        nameDomain: {
          active: false,
          title: t("servicePackages.packageFree.nameDomain"),
        },
        logoWebsite: {
          active: false,
          title: t("servicePackages.packageFree.logoWebsite"),
        },
        keyWeb: { title: t("servicePackages.intro.keyWeb") },
        albums: { title: t("servicePackages.packageFree.albums") },
        videos: { title: t("servicePackages.packageFree.videos") },
        branches: { title: t("servicePackages.packageFree.branches") },
        watermark: { title: t("servicePackages.packageFree.watermark") },
      },
    },
    {
      id: 2,
      key: "Basic",
      priceUnit: t("servicePackages.packageBasic.priceUnit"),
      btn: t("servicePackages.packageBasic.btn"),
      benefits: {
        keyFeature: { title: t("servicePackages.intro.keyFeature") },
        albumPerMonth: {
          active: true,
          title: t("servicePackages.packageBasic.albumPerMonth"),
        },
        timeLife: {
          active: true,
          title: t("servicePackages.packageBasic.timeLife"),
        },
        // faceFinder: {
        //   active: false,
        //   title: t('servicePackages.packageBasic.faceFinder'),
        // },
        downloadPhoto: {
          active: false,
          title: t("servicePackages.packageBasic.downloadPhoto"),
        },
        nameDomain: {
          active: false,
          title: t("servicePackages.packageBasic.nameDomain"),
        },
        logoWebsite: {
          active: false,
          title: t("servicePackages.packageBasic.logoWebsite"),
        },
        keyWeb: { title: t("servicePackages.intro.keyWeb") },
        albums: { title: t("servicePackages.packageBasic.albums") },
        videos: { title: t("servicePackages.packageBasic.videos") },
        branches: { title: t("servicePackages.packageBasic.branches") },
        watermark: { title: t("servicePackages.packageBasic.watermark") },
      },
    },
    {
      id: 3,
      key: "Professional",
      priceUnit: t("servicePackages.packageProfessional.priceUnit"),
      btn: t("servicePackages.packageProfessional.btn"),
      benefits: {
        keyFeature: { title: t("servicePackages.intro.keyFeature") },
        albumPerMonth: {
          active: true,
          title: t("servicePackages.packageProfessional.albumPerMonth"),
        },
        timeLife: {
          active: true,
          title: t("servicePackages.packageProfessional.timeLife"),
        },
        // faceFinder: {
        //   active: true,
        //   title: t('servicePackages.packageProfessional.faceFinder'),
        // },
        downloadPhoto: {
          active: true,
          title: t("servicePackages.packageProfessional.downloadPhoto"),
        },
        nameDomain: {
          active: true,
          title: t("servicePackages.packageProfessional.nameDomain"),
        },
        logoWebsite: {
          active: false,
          title: t("servicePackages.packageProfessional.logoWebsite"),
        },
        keyWeb: { title: t("servicePackages.intro.keyWeb") },
        albums: { title: t("servicePackages.packageProfessional.albums") },
        videos: { title: t("servicePackages.packageProfessional.videos") },
        branches: { title: t("servicePackages.packageProfessional.branches") },
      },
    },
    {
      id: 4,
      key: "Master",
      priceUnit: t("servicePackages.packageMaster.priceUnit"),
      btn: t("servicePackages.packageMaster.btn"),
      benefits: {
        keyFeature: { title: t("servicePackages.intro.keyFeature") },
        albumPerMonth: {
          active: true,
          title: t("servicePackages.packageMaster.albumPerMonth"),
        },
        timeLife: {
          active: true,
          title: t("servicePackages.packageMaster.timeLife"),
        },
        // faceFinder: {
        //   active: true,
        //   title: t('servicePackages.packageMaster.faceFinder'),
        // },
        downloadPhoto: {
          active: true,
          title: t("servicePackages.packageMaster.downloadPhoto"),
        },
        nameDomain: {
          active: true,
          title: t("servicePackages.packageMaster.nameDomain"),
        },
        logoWebsite: {
          active: true,
          title: t("servicePackages.packageMaster.logoWebsite"),
        },
        keyWeb: { title: t("servicePackages.intro.keyWeb") },
        albums: { title: t("servicePackages.packageMaster.albums") },
        videos: { title: t("servicePackages.packageMaster.videos") },
        branches: { title: t("servicePackages.packageMaster.branches") },
      },
    },
  ];
};

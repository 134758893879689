import { useParams } from "react-router-dom";
import BannerAlbumClient from "../components/Albums/BannerClient";
import ConfirmPassword from "../components/ConfirmPassword";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import { useGetAlbumBasicApi } from "../services/apis/album.api";

export interface IAnoLayout {
  type: "album-basic" | "album-public";
}

const AnoLayout = () => {
  const { albumId } = useParams();
  const { isLoading, data } = useGetAlbumBasicApi(albumId);

  if (isLoading) return <LoadingPage />;

  return (
    <div className="scroller flex flex-col w-full">
      <BannerAlbumClient
        customer_name={data.customer_name}
        name={data.name}
        thumbnail_url={data.thumbnail_url}
      />
      {!!data && (
        <div
          id="album-body"
          className="scroller-item w-full bg-white z-10 scroll-ruler overflow-y-auto sm:px-8 px-4"
        >
          <ConfirmPassword havePass={data.password} />
        </div>
      )}
    </div>
  );
};

export default AnoLayout;

import { subject } from "@casl/ability";
import { Form, Radio, Switch } from "antd";
import { useContext } from "react";
import { Trash2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import { TOption } from "../../types/common.type";
import UploadAlbum from "../UploadAbum";
import UploadVideo from "../UploadVideo";
import { TCreateWebsite } from "../../types/website.type";

const DEFAULT_VIDEOS = [null];
export const DEFAULT_ALBUMS = [];

const Products = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { videos: dfVideos, albums: dfAlbums } = useRootSelector(
    (state) => state.website
  );
  const user = useRootSelector((state) => state.user.userProfile);
  const ability = useContext(AbilityContext);
  const numberAlbum =
    user.membership_plan.plan_permissions?.find(
      (per) => per.name === PERMISSION.NUM_ALBUM_WEBSITE
    ).number || 0;
  const infinityAddNum = ability.can(
    "addInfinity",
    PERMISSION.NUM_ALBUM_WEBSITE
  )
    ? "∞"
    : numberAlbum;
  const isSuggestedAlbum =
    !ability.can("addInfinity", PERMISSION.NUM_ALBUM_WEBSITE) &&
    dfAlbums.length === numberAlbum;
  const isSuggestedVideo =
    !ability.can("addInfinity", PERMISSION.NUM_ALBUM_WEBSITE) &&
    dfVideos.length === numberAlbum;

  const layoutList: TOption<TCreateWebsite["album_layout"]>[] = [
    {
      value: "grid",
      label: t("formSetup.product.layoutGrid"),
    },
    {
      value: "list",
      label: t("formSetup.product.layoutList"),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <span className="font-semibold">{t("formSetup.product.album")}</span>
      <div
        id="albums"
        className={`flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8 relative ${
          dfAlbums.length === 0 ? "mb-2" : "mb-0"
        }`}
      >
        <div className="md:w-36 text-left w-full flex md:flex-col flex-row items-center md:items-start gap-1 flex-wrap">
          <span className="relative before:content-['*'] before:text-error before:mr-1 before:inline-block before:text-[14px] before:font-[SimSun] before:leading-none md:whitespace-pre-line flex flex-nowrap items-center">
            {t("formSetup.product.createAlbum", {
              value: dfAlbums.length + "/" + infinityAddNum,
            })}
          </span>
          {isSuggestedAlbum && (
            <div className="text-xs text-orange-600">
              {t("formSetup.product.note")}
            </div>
          )}
        </div>
        <Form.Item
          name="albums"
          layout="horizontal"
          className="mb-0 flex-1 w-full bg-gray-100 rounded-lg scroll-ruler overflow-x-auto"
        >
          <div className="w-max flex gap-2 p-2">
            {[...dfAlbums, ...DEFAULT_ALBUMS].map((item) => (
              <div
                className="relative w-32 sm:w-44 lg:w-48 h-24 sm:h-32"
                key={item.id}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setSetupWebsite({
                        albums: dfAlbums.filter(
                          (album) => album.id !== item.id
                        ),
                      })
                    );
                  }}
                  className=" cursor-pointer absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
                >
                  <Trash2 className="w-4" />
                </div>
                <img
                  src={item.thumbnail_url}
                  alt="uploaded"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
            <UploadAlbum />
          </div>
        </Form.Item>
        {dfAlbums.length === 0 && (
          <span className="md:left-[168px] lg:left-[176px] text-xs absolute block text-error -bottom-5">
            {t("formSetup.product.requireAlbum")}
          </span>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8">
        <span className="block md:w-36 text-left w-full">
          {t("formSetup.product.layout")}
        </span>
        <Form.Item
          name="album_layout"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
        >
          <Radio.Group name="album_layout" className="flex items-center gap-20">
            {layoutList.map((item) => (
              <Radio value={item.value} key={item.value}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>

      <span className="relative block w-full h-[1px] bg-slate-900/30"></span>

      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8">
        <div className="md:w-36 text-left w-full flex md:flex-col flex-row items-center md:items-start gap-1 flex-wrap">
          <span className="md:whitespace-pre-line flex flex-nowrap items-center">
            {t("formSetup.product.createVideo", {
              value: dfVideos.length + "/" + infinityAddNum,
            })}
          </span>
          {isSuggestedVideo && (
            <div className="text-xs text-orange-600">
              {t("formSetup.product.note")}
            </div>
          )}
        </div>
        <Form.Item
          name="videos"
          layout="horizontal"
          className="mb-0 flex-1 w-full bg-gray-100 rounded-lg scroll-ruler overflow-x-auto"
        >
          <div className="w-max flex gap-2 p-2">
            {[...dfVideos, ...DEFAULT_VIDEOS].map((video, index) => {
              return (
                <div
                  key={index}
                  className={`w-32 sm:w-44 lg:w-48 h-24 sm:h-32`}
                >
                  <UploadVideo
                    disabled={
                      index === dfVideos.length &&
                      !ability.can(
                        "add",
                        subject(PERMISSION.NUM_ALBUM_WEBSITE, {
                          max: dfVideos.length,
                        })
                      )
                    }
                    dfValue={video}
                    onValueChange={(data) => {
                      if (!data)
                        dispatch(
                          setSetupWebsite({
                            videos: dfVideos.filter(
                              (_item, idx) => index !== idx
                            ),
                          })
                        );
                      else {
                        const updatedVideos = [...dfVideos];
                        updatedVideos[index] = data;
                        dispatch(setSetupWebsite({ videos: updatedVideos }));
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8">
        <span className="block md:w-36 text-left w-full">
          {t("formSetup.product.layout")}
        </span>
        <Form.Item
          name="video_layout"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
        >
          <Radio.Group name="video_layout" className="flex items-center gap-20">
            {layoutList.map((item) => (
              <Radio value={item.value} key={item.value}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
    </div>
  );
};

export default Products;

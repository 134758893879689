import { useTranslation } from "react-i18next";
import PayPal from "./paypal";
import NganLuong from "./NganLuong";
import { Link } from "react-router-dom";
import SePay from "./SePay";

const Payment = ({
  total,
  subscriptionId,
}: {
  total: number;
  subscriptionId: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className="h-64 flex flex-col">
      <div className="flex mb-4 items-end">
        <span className="text-base">
          {t("servicePackages.payment.total") + ":"}
        </span>
        <span className="text-primary text-xl ml-2">
          {total?.toLocaleString("de-DE") +
            t("servicePackages.payment.priceUnit")}
        </span>
      </div>
      <div className="flex flex-col gap-2">
        <div className="text-base">
          {t("servicePackages.payment.btnPayment")}
        </div>
        <div className="flex gap-2 h-9">
          <div className="w-full">
            <PayPal subscriptionId={subscriptionId} />
          </div>
          <div className="w-full h-9">
            <NganLuong subscriptionId={subscriptionId} />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="w-full h-9">
            <SePay subscriptionId={subscriptionId} />
          </div>
        </div>
        <span className="text-gray-500 text-sm">
          {t("servicePackages.payment.note")}
          <Link
            to="https://shotpik.com/page/terms-of-use"
            className="text-secondary transition-all"
            target="_blank"
          >
            {" " + t("servicePackages.payment.policy")}
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Payment;

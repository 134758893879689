import { useTranslation } from "react-i18next";

type IBanner = {
  name: string;
  customer_name: string;
  thumbnail_url: string;
};

const BannerAlbumClient = (props: IBanner) => {
  const { customer_name, name, thumbnail_url } = props;
  const { t } = useTranslation();
  const logo = document
    .querySelector('meta[name="logo"]')
    ?.getAttribute("content");

  return (
    <div className="scroller-item h-screen w-full">
      <img
        src={thumbnail_url}
        className="absolute top-0 left-0 w-full h-full object-cover"
        alt="photo-detail"
      />
      <div className="absolute inset-0 bg-black bg-opacity-20"></div>
      <div className="m-auto flex justify-center items-center flex-col h-full text-white z-10 relative gap-4">
        <span className="font-semibold text-3xl md:text-5xl">
          {"Album " + name}
        </span>
        <div
          className={`flex items-center bg-transparent rounded-md text-md md:text-lg w-fit px-3 py-[6px] border hover:opacity-80 cursor-pointer`}
          onClick={() => {
            const element = document.getElementById("album-body");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          {t("albumDetail.seemore")}
        </div>
      </div>
      <div className="text-white absolute z-10 bottom-[15%] -translate-x-1/2 left-1/2 flex flex-col gap-3 items-center">
        {!!logo && (
          <img className="h-8 m-auto" src={logo} alt={customer_name} />
        )}
        <span className="font-semibold text-base md:text-2xl">
          {customer_name}
        </span>
      </div>
    </div>
  );
};

export default BannerAlbumClient;

import { subject } from "@casl/ability";
import { Form, Input, Switch } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CHANEL_CONTACT, SOCIAL } from "../../constants/website";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import UploadBranch from "../UploadBranch";

const DEFAULT_BRANCHES = [null];

const Contact = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dfBranches = useRootSelector((state) => state.website.branches);
  const ability = useContext(AbilityContext);
  const user = useRootSelector((state) => state.user.userProfile);
  const numberBranch =
    user.membership_plan.plan_permissions?.find(
      (per) => per.name === PERMISSION.NUM_BRANCH
    ).number || 0;
  const infinityAddBranch = ability.can("addInfinity", PERMISSION.NUM_BRANCH)
    ? "∞"
    : numberBranch;
  const isSuggested =
    !ability.can("addInfinity", PERMISSION.NUM_BRANCH) &&
    dfBranches.length === numberBranch;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8">
        <div className="md:w-36 text-left w-full flex md:flex-col flex-row items-center md:items-start gap-1 flex-wrap">
          <span className="md:whitespace-pre-line flex flex-nowrap items-center">
            {t("formSetup.contact.address", {
              value: dfBranches.length + "/" + infinityAddBranch,
            })}
          </span>
          {isSuggested && (
            <div className="text-xs text-orange-600">
              {t("formSetup.contact.note")}
            </div>
          )}
        </div>
        <Form.Item
          name="branches"
          layout="horizontal"
          className="mb-0 flex-1 w-full bg-gray-100 rounded-lg scroll-ruler overflow-x-auto"
        >
          <div className="w-max flex gap-2 p-2">
            {[...dfBranches, ...DEFAULT_BRANCHES].map((branch, index) => {
              return (
                <div
                  key={index}
                  className={`w-32 sm:w-44 lg:w-48 h-24 sm:h-32`}
                >
                  <UploadBranch
                    disabled={
                      index === dfBranches.length &&
                      !ability.can(
                        "add",
                        subject(PERMISSION.NUM_BRANCH, {
                          max: dfBranches.length,
                        })
                      )
                    }
                    dfValue={branch}
                    onValueChange={(data) => {
                      if (!data)
                        dispatch(
                          setSetupWebsite({
                            branches: dfBranches.filter(
                              (_item, idx) => index !== idx
                            ),
                          })
                        );
                      else {
                        const updatedBranches = [...dfBranches];
                        updatedBranches[index] = data;
                        dispatch(
                          setSetupWebsite({ branches: updatedBranches })
                        );
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        </Form.Item>
      </div>
      <div className="flex flex-col gap-2">
        <span className="font-semibold">{t("formSetup.contact.social")}</span>
        {SOCIAL.map((item) => (
          <div
            key={item.value}
            className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8"
          >
            <div className="sm:w-36 text-left w-full flex gap-2 items-center">
              {item.label}
            </div>
            <Form.Item
              name={["contacts", item.value]}
              layout="horizontal"
              className="mb-0 flex-1 w-full"
            >
              <Input placeholder={t("formSetup.contact.placeholder")} />
            </Form.Item>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <span className="font-semibold">{t("formSetup.contact.channel")}</span>
        {CHANEL_CONTACT.map((item) => (
          <div
            key={item.value}
            className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8"
          >
            <div className="sm:w-36 text-left w-full flex gap-2 items-center">
              {item.label}
            </div>
            <Form.Item
              name={["contacts", item.value]}
              layout="horizontal"
              className="mb-0 flex-1 w-full"
            >
              <Input placeholder={t("formSetup.contact.placeholder")} />
            </Form.Item>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;

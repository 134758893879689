import { TOption } from "../types/common.type";
import { TCreateWebsite } from "../types/website.type";

export const SOCIAL: TOption<keyof TCreateWebsite["contacts"]>[] = [
  {
    value: "facebook",
    label: "Facebook",
  },
  {
    value: "tiktok",
    label: "Tiktok",
  },
  {
    value: "x",
    label: "X (witter)",
  },
  {
    value: "instagram",
    label: "Instagram",
  },
  {
    value: "youtube",
    label: "Youtube",
  },
];

export const CHANEL_CONTACT: TOption<keyof TCreateWebsite["contacts"]>[] = [
  {
    value: "zalo",
    label: "Zalo",
  },
  {
    value: "messenger",
    label: "Messenger",
  },
  {
    value: "telegram",
    label: "Telegram",
  },
  {
    value: "line",
    label: "Line",
  },
];

const DEFAULT_MENU_LAYOUT: TCreateWebsite["menu_layout"] = "left";

const DEFAULT_THEME: TCreateWebsite["theme"] = "light";

const DEFAULT_ALBUM_LAYOUT: TCreateWebsite["album_layout"] = "grid";
const DEFAULT_VIDEO_LAYOUT: TCreateWebsite["album_layout"] = "grid";

export const DEFAULT_GENERAL_SETTING: Partial<TCreateWebsite> = {
  title: "",
  slogan: "",
  menu_layout: DEFAULT_MENU_LAYOUT,
  theme: DEFAULT_THEME,
};

export const DEFAULT_INTRO_IMAGES = [...Array.from({ length: 3 }, () => null)];

export enum DEFAULT_INTRBG {
  PHOTO = "photo",
  VIDEO = "video",
}

export const DEFAULT_INTRODUCE: Partial<TCreateWebsite> = {
  intro_background: DEFAULT_INTRBG.PHOTO,
  intro_content: "",
  intro_images: DEFAULT_INTRO_IMAGES,
};

export const DEFAULT_PRODUCTS: Partial<TCreateWebsite> = {
  albums: [],
  videos: [],
  album_layout: DEFAULT_ALBUM_LAYOUT,
  video_layout: DEFAULT_VIDEO_LAYOUT,
};

export const DEFAULT_ESTIMATES: Partial<TCreateWebsite> = {
  pricing_images: [],
  pricing_note: "",
};

export const DEFAULT_CONTACT: Partial<TCreateWebsite> = {
  branches: [],
  contacts: {
    facebook: "",
    tiktok: "",
    x: "",
    instagram: "",
    zalo: "",
    messenger: "",
    telegram: "",
    line: "",
    youtube: "",
  },
};

export const DEFAULT_SETUP_WEBSITE = {
  ...DEFAULT_GENERAL_SETTING,
  ...DEFAULT_INTRODUCE,
  ...DEFAULT_PRODUCTS,
  ...DEFAULT_ESTIMATES,
  ...DEFAULT_CONTACT,
};

const CONTENT1 =
  "Chào mừng bạn đến với STUDIO, nơi chuyên nghiệp trong việc ghi lại những khoảnh khắc đáng nhớ qua từng bức ảnh đầy cảm xúc. Với nhiều năm kinh nghiệm, chúng tôi tự hào mang đến những sản phẩm nhiếp ảnh chất lượng cao, giúp bạn lưu giữ những kỷ niệm đẹp nhất.\n\nẢnh cưới & Pre-wedding – Ghi lại câu chuyện tình yêu bằng những bộ ảnh lãng mạn, tinh tế, mang dấu ấn riêng của từng cặp đôi.\nChân dung ngoại cảnh – Tạo nên những bức ảnh chân dung ấn tượng, tự nhiên, phù hợp cho cá nhân, nghệ sĩ hoặc doanh nhân.\nTiệc & Phóng sự sự kiện – Lưu giữ những khoảnh khắc quan trọng của các buổi tiệc, sự kiện, hội nghị bằng phong cách chụp tự nhiên, chân thực.\n\nChúng tôi không chỉ chụp ảnh mà còn kể câu chuyện qua từng khung hình. Đội ngũ STUDIO luôn tận tâm, sáng tạo để mang đến cho bạn những sản phẩm độc đáo và chất lượng nhất.\n\nLiên hệ ngay để đặt lịch chụp và tư vấn chi tiết!";

const CONTENT2 =
  "STUDIO – Ghi Dấu Khoảnh Khắc Đáng Nhớ\n\nTại STUDIO, chúng tôi không chỉ chụp ảnh, mà còn lưu giữ những câu chuyện, cảm xúc và khoảnh khắc ý nghĩa nhất trong cuộc sống của bạn. Với phong cách chuyên nghiệp, sáng tạo và tận tâm, chúng tôi mang đến những bức ảnh giàu cảm xúc, đầy nghệ thuật.\n\nDịch vụ của chúng tôi:\nẢnh cưới & Pre-wedding: Lưu giữ tình yêu bằng những bức ảnh lãng mạn, tinh tế.\nChân dung ngoại cảnh: Tôn vinh vẻ đẹp tự nhiên với những góc chụp đầy cảm xúc.\nTiệc & Phóng sự sự kiện: Ghi lại những khoảnh khắc quan trọng một cách chân thực nhất.\n\nChúng tôi tin rằng mỗi bức ảnh đều có một câu chuyện riêng. Hãy để STUDIO đồng hành cùng bạn trong hành trình lưu giữ những kỷ niệm quý giá! Liên hệ ngay để được tư vấn chi tiết.";

const CONTENT3 =
  "STUDIO – Lưu Giữ Khoảnh Khắc, Kể Câu Chuyện Bằng Hình Ảnh\n\nTại STUDIO, chúng tôi chuyên chụp ảnh cưới, pre-wedding, chân dung ngoại cảnh, tiệc và phóng sự sự kiện. Với sự tận tâm và sáng tạo, chúng tôi không chỉ chụp ảnh mà còn giúp bạn lưu giữ những kỷ niệm đẹp bằng những khung hình đầy cảm xúc.\n\nMỗi bức ảnh là một câu chuyện, mỗi khoảnh khắc đều đáng trân trọng. Chúng tôi cam kết mang đến những bức ảnh chất lượng, tự nhiên và chân thực nhất.\n\nHãy để STUDIO đồng hành cùng bạn trong những sự kiện quan trọng. Liên hệ ngay để đặt lịch chụp và tư vấn chi tiết!";

const CONTENT4 =
  "STUDIO – Ghi Dấu Những Khoảnh Khắc Đẹp Nhất\n\nTại STUDIO, chúng tôi tin rằng mỗi khoảnh khắc đều xứng đáng được lưu giữ một cách trọn vẹn và ý nghĩa. Với dịch vụ chụp ảnh cưới, pre-wedding, chân dung ngoại cảnh, tiệc và phóng sự sự kiện, chúng tôi mang đến những bức ảnh tự nhiên, giàu cảm xúc và đậm chất nghệ thuật.\n\nBằng sự tận tâm và sáng tạo, STUDIO luôn cố gắng ghi lại những khoảnh khắc đẹp nhất trong từng khung hình. Chúng tôi không chỉ chụp ảnh mà còn giúp bạn kể câu chuyện của chính mình.\n\nLiên hệ ngay để cùng STUDIO tạo nên những kỷ niệm đáng nhớ!";

const CONTENT5 =
  "STUDIO – Nơi Khoảnh Khắc Trở Thành Kỷ Niệm\n\nTại STUDIO, chúng tôi chuyên chụp ảnh cưới, pre-wedding, chân dung ngoại cảnh, tiệc và phóng sự sự kiện với phong cách tự nhiên, cảm xúc và sáng tạo. Mỗi bức ảnh không chỉ là một tấm hình, mà còn là câu chuyện, cảm xúc và dấu ấn riêng của bạn.\n\nVới kinh nghiệm và sự tận tâm, STUDIO luôn hướng đến việc tạo ra những sản phẩm nhiếp ảnh chất lượng, giúp bạn lưu giữ những khoảnh khắc quan trọng một cách chân thực và ý nghĩa nhất.\n\nHãy để STUDIO đồng hành cùng bạn trong hành trình lưu giữ những kỷ niệm đáng nhớ!";

const CONTENT6 =
  "STUDIO – Lưu Giữ Những Khoảnh Khắc Ý Nghĩa\n\nCuộc sống là tập hợp của những khoảnh khắc đẹp, và STUDIO ở đây để giúp bạn ghi lại những phút giây đáng nhớ ấy. Chúng tôi chuyên chụp ảnh cưới, pre-wedding, chân dung ngoại cảnh, tiệc và phóng sự sự kiện, mang đến những bức ảnh tự nhiên, giàu cảm xúc và đầy nghệ thuật.\n\nVới sự tận tâm và kinh nghiệm, chúng tôi không chỉ chụp ảnh mà còn kể câu chuyện của bạn qua từng khung hình. Mỗi bức ảnh là một kỷ niệm, một cảm xúc được lưu giữ mãi mãi.\n\nHãy để STUDIO đồng hành cùng bạn trong những khoảnh khắc quan trọng nhất!";

const CONTENT7 =
  "STUDIO – Ghi Lại Những Khoảnh Khắc Đáng Nhớ\n\nTại STUDIO, chúng tôi tin rằng mỗi khoảnh khắc đều đáng trân trọng và xứng đáng được lưu giữ một cách hoàn hảo. Với dịch vụ chụp ảnh cưới, pre-wedding, chân dung ngoại cảnh, tiệc và phóng sự sự kiện, chúng tôi mang đến những bức ảnh đầy cảm xúc, chân thực và nghệ thuật.\n\nVới sự tận tâm và sáng tạo, STUDIO cam kết mang lại những khung hình đẹp nhất, giúp bạn lưu giữ những kỷ niệm đáng giá theo cách riêng của mình.\n\nLiên hệ ngay để cùng STUDIO tạo nên những khoảnh khắc không thể quên!";

const CONTENT8 =
  "STUDIO – Chạm Đến Cảm Xúc Qua Từng Khung Hình\n\nMỗi bức ảnh không chỉ là một khoảnh khắc, mà còn là câu chuyện, cảm xúc và ký ức. STUDIO luôn đặt trọn tâm huyết vào từng khung hình, giúp bạn lưu giữ những kỷ niệm đẹp nhất một cách tự nhiên và chân thực.\n\nDù là ảnh cưới lãng mạn, pre-wedding sáng tạo, chân dung ngoại cảnh ấn tượng hay phóng sự sự kiện đầy cảm xúc, chúng tôi đều mang đến chất lượng tốt nhất, phản ánh đúng nét đẹp và cá tính riêng của mỗi khách hàng.\n\nHãy để STUDIO đồng hành cùng bạn trong hành trình lưu giữ những khoảnh khắc đáng nhớ nhất!";

const CONTENT9 =
  "STUDIO – Tận Tâm, Sáng Tạo, Khác Biệt\n\nChúng tôi hiểu rằng mỗi khách hàng đều có câu chuyện riêng, và STUDIO luôn nỗ lực để thể hiện điều đó qua từng bức ảnh. Không chỉ đơn thuần là chụp hình, chúng tôi mang đến những khoảnh khắc chân thật, những cảm xúc trọn vẹn và những tác phẩm nghệ thuật mang dấu ấn cá nhân.\n\nVới đội ngũ giàu kinh nghiệm, thiết bị hiện đại và sự sáng tạo không ngừng, STUDIO cam kết mang đến những bức ảnh chất lượng, giúp bạn lưu giữ những ký ức quý giá theo cách đặc biệt nhất.\n\nHãy để STUDIO giúp bạn biến từng khoảnh khắc thành kỷ niệm vĩnh cửu!";

const CONTENT10 =
  "STUDIO – Đẳng Cấp Từ Chi Tiết Nhỏ Nhất\n\nChúng tôi tin rằng một bức ảnh đẹp không chỉ đến từ kỹ thuật mà còn từ cảm xúc và câu chuyện đằng sau nó. STUDIO luôn chú trọng đến từng chi tiết, từ ánh sáng, góc chụp đến cách bắt trọn những khoảnh khắc tự nhiên nhất, tạo nên những tác phẩm đầy nghệ thuật và ý nghĩa.\n\nDù là khoảnh khắc ngọt ngào trong lễ cưới, sự hạnh phúc trong ngày kỷ niệm hay những phút giây ấm áp bên gia đình, STUDIO luôn sẵn sàng đồng hành cùng bạn, ghi lại những dấu ấn đẹp nhất trong cuộc đời.\n\nHãy để STUDIO biến những khoảnh khắc quý giá của bạn thành những kỷ niệm không bao giờ phai nhạt!";

const CONTENT11 =
  "STUDIO – Lưu Giữ Ký Ức Theo Cách Riêng Của Bạn\n\nMỗi người có một câu chuyện, một cảm xúc và một cách nhìn riêng về cái đẹp. STUDIO không chỉ đơn thuần chụp ảnh mà còn giúp bạn thể hiện cá tính, phong cách và cảm xúc chân thật nhất qua từng khung hình.\n\nChúng tôi không chạy theo xu hướng mà tập trung vào giá trị bền vững – những bức ảnh sẽ luôn đẹp và ý nghĩa theo thời gian. Dù là ảnh cưới, pre-wedding, chân dung hay phóng sự sự kiện, chúng tôi đều chăm chút từng khoảnh khắc để mang đến những tác phẩm hoàn hảo nhất.\n\nHãy để STUDIO đồng hành cùng bạn trong hành trình lưu giữ những khoảnh khắc đáng nhớ nhất!";

const CONTENT12 =
  "STUDIO – Mỗi Bức Ảnh Là Một Câu Chuyện\n\nChúng tôi tin rằng nhiếp ảnh không chỉ đơn thuần là ghi lại hình ảnh, mà còn là cách kể câu chuyện qua từng ánh mắt, nụ cười và khoảnh khắc tự nhiên nhất. STUDIO luôn hướng đến sự chân thật và cảm xúc, giúp bạn lưu giữ những ký ức theo cách đặc biệt nhất.\n\nTừ ảnh cưới lãng mạn, pre-wedding sáng tạo, chân dung ngoại cảnh nghệ thuật đến phóng sự sự kiện chuyên nghiệp, chúng tôi đều đặt trọn tâm huyết để mỗi bức ảnh đều mang dấu ấn riêng, phản ánh đúng cảm xúc và phong cách của bạn.\n\nHãy để STUDIO biến những khoảnh khắc quan trọng trong cuộc đời bạn thành những tác phẩm nghệ thuật đáng nhớ!";

const CONTENT13 =
  "STUDIO – Nơi Cảm Xúc Thăng Hoa Qua Ống Kính\n\nMỗi khoảnh khắc trong cuộc sống đều đáng được lưu giữ theo cách đặc biệt nhất. STUDIO không chỉ chụp ảnh, mà còn giúp bạn ghi lại những phút giây ý nghĩa với phong cách tự nhiên, tinh tế và đầy cảm xúc.\n\nDù đó là khoảnh khắc hạnh phúc trong ngày cưới, sự rạng rỡ trong bộ ảnh pre-wedding, nét cá tính trong ảnh chân dung hay những giây phút đáng nhớ tại sự kiện quan trọng, chúng tôi luôn sẵn sàng đồng hành để tạo nên những bức ảnh mang giá trị bền vững theo thời gian.\n\nHãy để STUDIO giúp bạn biến từng khoảnh khắc thành kỷ niệm đẹp mãi mãi!";

const CONTENT14 =
  "STUDIO – Chất Lượng Tạo Nên Sự Khác Biệt\n\nVới STUDIO, mỗi bức ảnh không chỉ là một khoảnh khắc mà còn là một tác phẩm nghệ thuật. Chúng tôi luôn chú trọng đến từng chi tiết, từ ánh sáng, bố cục đến cảm xúc của nhân vật, để mang đến những bức hình chân thực và đầy ấn tượng.\n\nSự chuyên nghiệp và tận tâm của chúng tôi thể hiện qua từng bộ ảnh cưới, pre-wedding, chân dung, tiệc và phóng sự sự kiện. Dù bạn mong muốn một bộ ảnh lãng mạn, cá tính hay trang trọng, STUDIO sẽ giúp bạn hiện thực hóa một cách hoàn hảo nhất.\n\nHãy để STUDIO ghi dấu những khoảnh khắc quan trọng nhất trong cuộc đời bạn!";

const CONTENT15 =
  "STUDIO – Nơi Lưu Giữ Những Cảm Xúc Chân Thật\n\nKhoảnh khắc trôi qua, nhưng kỷ niệm sẽ còn mãi với thời gian. STUDIO mang đến những bức ảnh không chỉ đẹp về hình thức mà còn chứa đựng những cảm xúc chân thật nhất. Mỗi khung hình đều được ghi lại với sự tận tâm, giúp bạn lưu giữ những giây phút đáng nhớ theo cách tự nhiên và nghệ thuật nhất.\n\nDù đó là ảnh cưới, pre-wedding, chân dung hay phóng sự sự kiện, chúng tôi cam kết mang lại những bức ảnh có chiều sâu, thể hiện rõ câu chuyện và phong cách riêng của bạn.\n\nHãy để STUDIO đồng hành cùng bạn trong từng khoảnh khắc quan trọng!";

const CONTENT16 =
  "STUDIO – Mỗi Khoảnh Khắc Là Một Tác Phẩm Nghệ Thuật\n\nTại STUDIO, chúng tôi không chỉ ghi lại hình ảnh, mà còn tạo ra những tác phẩm mang dấu ấn riêng biệt. Với sự kết hợp giữa kỹ thuật chuyên nghiệp và cảm xúc chân thật, chúng tôi giúp bạn lưu giữ những khoảnh khắc quý giá theo cách đặc biệt nhất.\n\nDù là ảnh cưới, pre-wedding, chân dung ngoại cảnh hay phóng sự sự kiện, mỗi bức ảnh đều được thực hiện với sự tỉ mỉ và sáng tạo, đảm bảo thể hiện trọn vẹn câu chuyện của bạn.\n\nHãy để STUDIO giúp bạn biến từng khoảnh khắc thành những kỷ niệm không thể nào quên!";

const CONTENT17 =
  "STUDIO – Nơi Cảm Xúc Được Ghi Dấu Mãi Mãi\n\nChúng tôi tin rằng những khoảnh khắc đẹp nhất trong cuộc sống xứng đáng được lưu giữ trọn vẹn. STUDIO không chỉ chụp ảnh, mà còn giúp bạn kể câu chuyện của riêng mình qua từng khung hình đầy cảm xúc.\n\nTừ ảnh cưới, pre-wedding, chân dung ngoại cảnh đến phóng sự sự kiện, mỗi bức ảnh đều phản ánh chân thực vẻ đẹp và cảm xúc của bạn, giúp những kỷ niệm quý giá trở thành tác phẩm nghệ thuật mang giá trị vĩnh cửu.\n\nHãy để STUDIO đồng hành cùng bạn, biến từng khoảnh khắc thành những ký ức không bao giờ phai!";

const CONTENT18 =
  "STUDIO – Ghi Lại Khoảnh Khắc, Lưu Giữ Ký Ức\n\nMỗi bức ảnh là một lát cắt của thời gian, là nơi cảm xúc được lưu giữ mãi mãi. STUDIO tự hào đồng hành cùng bạn trong những sự kiện quan trọng, ghi lại những khoảnh khắc đẹp bằng phong cách tự nhiên, chân thực và đầy nghệ thuật.\n\nDù là ảnh cưới, pre-wedding, chân dung ngoại cảnh hay phóng sự sự kiện, chúng tôi luôn chú trọng từng chi tiết, đảm bảo mỗi bức ảnh không chỉ đẹp mà còn có hồn, phản ánh đúng cảm xúc và câu chuyện của bạn.\n\nHãy để STUDIO giúp bạn biến những khoảnh khắc quý giá thành kỷ niệm đáng nhớ theo năm tháng!";

export const RANDOM_INTRO_CONTENT = [
  CONTENT1,
  CONTENT2,
  CONTENT3,
  CONTENT4,
  CONTENT5,
  CONTENT6,
  CONTENT7,
  CONTENT8,
  CONTENT9,
  CONTENT10,
  CONTENT11,
  CONTENT12,
  CONTENT13,
  CONTENT14,
  CONTENT15,
  CONTENT16,
  CONTENT17,
  CONTENT18,
];

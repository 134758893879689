import { Modal } from "antd";
import { useContext, useState } from "react";
import { Upload as UploadIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { TWebAlbumModal } from "../../types/website.type";
import CreateEditAlbum from "../Albums/CreateEditAlbum";
import ListAlbum from "./ListAlbum";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import { subject } from "@casl/ability";
import { useRootSelector } from "../../stores/reducers/root";

const UploadAlbum = () => {
  const { t } = useTranslation();
  const ability = useContext(AbilityContext);
  const albums = useRootSelector((state) => state.website.albums);
  const [isModalOpen, setIsModalOpen] = useState<TWebAlbumModal>(null);
  const disabled = !ability.can(
    "add",
    subject(PERMISSION.NUM_ALBUM_WEBSITE, {
      max: albums.length,
    })
  );

  return (
    <>
      <div
        className={`w-28 sm:w-44 lg:w-48 h-24 sm:h-32 bg-white flex flex-col gap-2 justify-center items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg transition-all text-xs sm:text-sm ${
          disabled ? "cursor-default opacity-50" : "cursor-pointer"
        }`}
        onClick={() => {
          if (!disabled) setIsModalOpen({ status: "list" });
        }}
      >
        <UploadIcon className="w-5" />
        <span>{t("formSetup.product.addAlbum")}</span>
      </div>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("formSetup.product.addAlbum")}
          </div>
        }
        open={!!isModalOpen}
        onCancel={() => {
          setIsModalOpen(null);
        }}
        {...(isModalOpen?.status === "list" ? { width: 1000 } : {})}
        footer={null}
        centered
      >
        {isModalOpen?.status === "list" ? (
          <ListAlbum
            setIsOpen={(data) => {
              if (data?.status === "create") setIsModalOpen(data);
              else setIsModalOpen(null);
            }}
          />
        ) : isModalOpen?.status === "create" ? (
          <CreateEditAlbum
            config={isModalOpen.config}
            onClick={(data) => {
              !!data && setIsModalOpen(null);
            }}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default UploadAlbum;

import { Modal } from "antd";
import { memo, useState } from "react";
import { MapPin, Trash2, Upload as UploadIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import BranchForm from "./BranchForm";
import { TCreateWebsite } from "../../types/website.type";

interface IUploadBranch {
  onValueChange: (value: TCreateWebsite["branches"][number]) => void;
  dfValue?: TCreateWebsite["branches"][number];
  disabled?: boolean;
}

const UploadBranch = memo((props: IUploadBranch) => {
  const { dfValue, onValueChange, disabled = false } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={`relative w-full h-full ${
          disabled ? "cursor-default opacity-50" : "cursor-pointer"
        }`}
        onClick={() => {
          if (!disabled) setIsModalOpen(true);
        }}
      >
        {!!dfValue ? (
          <div className="relative w-full h-full bg-white rounded-lg flex justify-center items-center">
            <div
              onClick={(e) => {
                e.stopPropagation();
                onValueChange(null);
              }}
              className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
            >
              <Trash2 className="w-4" />
            </div>
            <MapPin className="w-5" />
          </div>
        ) : (
          <div className="w-full h-full flex flex-col gap-2 justify-center bg-white items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg transition-all text-xs sm:text-sm">
            <UploadIcon className="w-5" />
            <span>{t("formSetup.contact.addBranch")}</span>
          </div>
        )}
      </div>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("formSetup.contact.addBranch")}
          </div>
        }
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={null}
      >
        <BranchForm
          onValueChange={(data) => {
            if (!!data) {
              onValueChange(data);
            }
            setIsModalOpen(false);
          }}
          dfValue={dfValue}
        />
      </Modal>
    </>
  );
});

export default UploadBranch;

import { TMembershipPlanPrice } from "../types/membership.type";
import clsx from "clsx";
import { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import ZipDownloader from "../services/zip.service";
import { TImage } from "../types/album.type";

export const createURLSearchParams = (object: any): string => {
  const ignoreValues = ["", "undefined", "null", null, undefined];
  let params = new URLSearchParams(object);
  let keysForDel: string[] = [];
  params.forEach((value, key) => {
    if (ignoreValues.includes(value)) {
      keysForDel.push(key);
    }
  });
  keysForDel.forEach((key) => {
    params.delete(key);
  });
  return params.toString();
};

export const clusterPackage = (list: TMembershipPlanPrice[]) => {
  let a: Record<number, TMembershipPlanPrice[]> = {};
  for (const value of list) {
    if (!a[value?.membership_plan_id]) {
      a[value?.membership_plan_id] = [value];
    } else {
      a[value?.membership_plan_id] = [...a[value?.membership_plan_id], value];
    }
  }
  return a;
};

export const focusValidation = (errorInfo: any, formName: string) => {
  const firstErrorField = errorInfo.errorFields[0];
  if (firstErrorField) {
    const inputNode = document.getElementById(
      `${formName}_${firstErrorField.name[0]}`
    );
    if (inputNode) {
      (inputNode as HTMLElement).focus();
    }
  }
};

export const focusValidationId = (
  id: string,
  block?: ScrollLogicalPosition
) => {
  const inputNode = document.getElementById(id);
  if (inputNode) {
    inputNode.scrollIntoView({ behavior: "smooth", block: block || "center" });
  }
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertToEmbedUrl(youtubeUrl: string) {
  const regex =
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?([^&\s]+)/;
  const match = youtubeUrl.match(regex);

  if (match && match[5]) {
    return `https://www.youtube.com/embed/${match[5]}`;
  } else {
    return null;
  }
}

export const getYouTubeThumbnail = (
  url: string,
  quality: "mq" | "hq" | "maxres" = "maxres"
) => {
  const match = url.match(
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/
  );
  if (!match) return "";

  return `https://img.youtube.com/vi/${match[1]}/${quality}default.jpg`;
};

export const zipDownLoader = async ({
  images,
  name,
}: {
  images: TImage[];
  name: string;
}) => {
  const zip = new ZipDownloader();
  const imagelist = images.map((image) => {
    const cleanUrl = new URL(image.url).origin + new URL(image.url).pathname;
    return {
      url: cleanUrl,
      name: image.file_name,
      folder_id: image.folder_id,
      folder_name: image.folder_name,
    };
  });
  await zip.addMulti(imagelist, 20, name);
  zip.save(name);
};

export function replaceContentTxt(content: string) {
  return content.replace(/<b[^>]*>(.*?)<\/b>/g, "$1");
}

export const downloadTxtFile = (content: string, name: string) => {
  const plainText = replaceContentTxt(content);
  const blob = new Blob([plainText], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${name}.txt`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const groupByFolderAndTags = (
  data: TImage[],
  isTag: boolean,
  isExtension: boolean,
  isComment: boolean
) => {
  return data.reduce((acc, item) => {
    const folderId = item.folder_id;
    const folderName = item.folder_name;

    if (!acc[folderId]) {
      acc[folderId] = { folder_name: folderName, tags: {}, images: [] };
    }

    if (isTag) {
      item.tags.forEach((tag) => {
        if (!acc[folderId].tags[tag.id]) {
          acc[folderId].tags[tag.id] = { tag_name: tag.name, images: [] };
        }
        acc[folderId].tags[tag.id].images.push(
          isExtension ? item.file_name : item.file_name.replace(/\.[^/.]+$/, "")
        );
      });
    } else if (isComment) {
      const fileName = isExtension
        ? item.file_name
        : item.file_name.replace(/\.[^/.]+$/, "");

      acc[folderId].images.push(
        `${fileName} : ${item.comments.find((comment) => comment.comment !== '')?.comment}`
      );
    } else {
      acc[folderId].images.push(
        isExtension ? item.file_name : item.file_name.replace(/\.[^/.]+$/, "")
      );
    }
    return acc;
  }, {});
};

export function formatGroupedData(
  groups: Record<string, any>,
  valueJoin: string
): string {
  let output = "";
  for (const folderId in groups) {
    const folder = groups[folderId];
    output += `<b class="text-gray-600 text-base">${folder.folder_name}</b>\n`;
    if (Object.keys(folder.tags).length > 0) {
      for (const tagId in folder.tags) {
        const tag = folder.tags[tagId];
        output += `<b class="text-gray-600">${tag.tag_name}</b>\n`;
        output += `${tag.images.join(valueJoin)}\n`;
      }
    } else {
      output += `${folder.images.join(valueJoin)}`;
    }
    output += "\n";
  }

  return output;
}

export function isIOS() {
  return ["iPhone", "iPad", "iPod"].includes(navigator.platform);
}

import { Spin } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { Trash2, Upload as UploadIcon } from "react-feather";
import { useUploadPhotoApi } from "../../services/apis/common.api";

interface IUploadPhoto {
  onValueChange: (path: string) => void;
  dfUrl?: string;
  content?: string;
  disabled?: boolean;
}

const UploadPhoto = memo((props: IUploadPhoto) => {
  const { dfUrl, onValueChange, content = "", disabled } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { mutate: mutationUpload, isLoading: loadingUpload } =
    useUploadPhotoApi();
  const [uploadedPhoto, setUpLoadedPhoto] = useState<string>(null);
  const handleOpenFile = () => {
    if (!!inputRef.current) inputRef.current.click();
  };
  const handleChange = (event: any) => {
    if (loadingUpload) return;
    if (event.target?.files) {
      const photo = new FormData();
      photo.append("image", event.target?.files[0]);
      mutationUpload(photo, {
        onSuccess: (data) => {
          setUpLoadedPhoto(data.data.path);
          onValueChange?.(data.data.path);
        },
        onError: (error) => {},
      });
    }
  };

  useEffect(() => {
    setUpLoadedPhoto(dfUrl);
  }, [dfUrl]);

  return (
    <div className="relative w-full h-full bg-white rounded-lg">
      <input
        type="file"
        ref={inputRef}
        accept="image/gif, image/jpeg, image/png, image/jpg, image/webp"
        className="!hidden"
        onChange={handleChange}
      />
      {loadingUpload && (
        <div
          className={`absolute top-0 cursor-not-allowed left-0 w-full h-full after:absolute after:w-full after:h-full after:bg-white after:content-[''] after:opacity-50 flex justify-center items-center z-30`}
        >
          <Spin />
        </div>
      )}
      <div className="relative w-full h-full cursor-pointer">
        {!!uploadedPhoto ? (
          <div
            className="relative w-full h-full"
            onClick={() => {
              handleOpenFile();
            }}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                setUpLoadedPhoto("");
                onValueChange?.(null);
              }}
              className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
            >
              <Trash2 className="w-4" />
            </div>
            <img
              src={uploadedPhoto}
              alt="uploaded"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        ) : (
          <div
            className={`w-full h-full flex flex-col gap-2 justify-center items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg transition-all text-xs sm:text-sm ${
              disabled ? "cursor-default opacity-50" : "cursor-pointer"
            }`}
            onClick={() => {
              if (!disabled) handleOpenFile();
            }}
          >
            <UploadIcon className="w-5" />
            <span>{content}</span>
          </div>
        )}
      </div>
    </div>
  );
});

export default UploadPhoto;

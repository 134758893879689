import { useMutation, useQuery } from "react-query";
import { setupWebEndpoint } from "../endpoints";
import http from "../http.service";
import { queryKeys } from "../../constants/queryKeys";
import { TCreateWebsite, TWebsiteResponse } from "../../types/website.type";

export const useSetupWebApi = () => {
  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return await http.post<TCreateWebsite>(setupWebEndpoint.CREATE, data);
    },
  });
  return mutation;
};

export const useActiveWebApi = () => {
  const mutation = useMutation({
    mutationFn: async () => {
      return await http.put<TCreateWebsite>(setupWebEndpoint.ACTIVE);
    },
  });
  return mutation;
};

export const useGetWebsiteApi = () => {
  const query = useQuery([queryKeys.GET_WEBSITE], async () => {
    const res = await http.get<TWebsiteResponse>(setupWebEndpoint.CREATE);
    return res;
  });
  return {
    isLoading: query.isLoading,
    data: query.data?.data?.data,
    refetch: query.refetch,
  };
};

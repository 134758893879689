import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import Languages from "../components/Languages";
import { path } from "../routes/path";

const AuthenLayout = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.scrollY > 30) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const logo = document
    .querySelector('meta[name="logo"]')
    ?.getAttribute("content");

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      <div
        className={`sm:px-8 px-4 h-14 md:h-16 transition-all bg-white ${
          isVisible ? "shadow" : "shadow-none"
        } flex items-center justify-between sticky top-0 left-0 right-0 z-40 w-full`}
      >
        <Link to={path.home} className="text-black text-2xl font-semibold">
          {!!logo ? (
            <img className="h-8" src={logo} alt="Shotpik" />
          ) : (
            "Shotpik"
          )}
        </Link>
        <div className="flex space-x-4 md:space-x-8">
          <div className="cursor-pointer text-slate-700 hover:text-primary transition-all flex justify-center items-center">
            <Languages />
          </div>
          <Link to={path.home}>
            <X className="text-slate-500 hover:rotate-90 transition-all" />
          </Link>
        </div>
      </div>
      <div className="max-w-[1440px] m-auto sm:px-8 px-2 flex flex-col">
        <div
          className={`fixed left-0 top-0 h-full w-full z-[-1] bg-cover bg-no-repeat bg-center`}
        ></div>
        <div className="relative w-full sm:pt-4">
          <Outlet />
          {children}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: t("footer.copy"),
          }}
          className="text-xs sm:text-sm text-center w-full py-4 border-t mt-4 block"
        ></div>
      </div>
    </>
  );
};

export default AuthenLayout;

import { Form, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import UploadPhoto from "../UploadPhoto";
import { useContext } from "react";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import { subject } from "@casl/ability";

const DEFAULT_ESTIMATES = [""];

const Estimate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dfPricingImages = useRootSelector(
    (state) => state.website.pricing_images
  );
  const user = useRootSelector((state) => state.user.userProfile);
  const ability = useContext(AbilityContext);
  const numberAlbum =
    user.membership_plan.plan_permissions?.find(
      (per) => per.name === PERMISSION.NUM_ALBUM_WEBSITE
    ).number || 0;
  const infinityAddNum = ability.can(
    "addInfinity",
    PERMISSION.NUM_ALBUM_WEBSITE
  )
    ? "∞"
    : numberAlbum;
  const isSuggested =
    !ability.can("addInfinity", PERMISSION.NUM_ALBUM_WEBSITE) &&
    dfPricingImages.length === numberAlbum;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8">
        <div className="md:w-36 text-left w-full flex md:flex-col flex-row items-center md:items-start gap-1 flex-wrap">
          <span className="md:whitespace-pre-line flex flex-nowrap items-center">
            {t("formSetup.estimate.createEstimate", {
              value: dfPricingImages.length + "/" + infinityAddNum,
            })}
          </span>
          {isSuggested && (
            <div className="text-xs text-orange-600">
              {t("formSetup.estimate.note")}
            </div>
          )}
          <a
            href="/page/PSDbaogia"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500"
          >
            {t("formSetup.estimate.examplePSD")}
          </a>
        </div>
        <Form.Item
          name="pricing_images"
          layout="horizontal"
          className="mb-0 flex-1 w-full bg-gray-100 rounded-lg scroll-ruler overflow-x-auto"
        >
          <div className="w-max flex gap-2 p-2">
            {[...dfPricingImages, ...DEFAULT_ESTIMATES].map(
              (estimate, index) => {
                return (
                  <div
                    key={index}
                    className={`w-32 sm:w-44 lg:w-48 h-24 sm:h-32`}
                  >
                    <UploadPhoto
                      disabled={
                        index === dfPricingImages.length &&
                        !ability.can(
                          "add",
                          subject(PERMISSION.NUM_ALBUM_WEBSITE, {
                            max: dfPricingImages.length,
                          })
                        )
                      }
                      dfUrl={estimate}
                      onValueChange={(data) => {
                        if (!data)
                          dispatch(
                            setSetupWebsite({
                              pricing_images: dfPricingImages.filter(
                                (_item, idx) => index !== idx
                              ),
                            })
                          );
                        else {
                          const updatedEstimates = [...dfPricingImages];
                          updatedEstimates[index] = data;
                          dispatch(
                            setSetupWebsite({
                              pricing_images: updatedEstimates,
                            })
                          );
                        }
                      }}
                      content={t("formSetup.estimate.addPage")}
                    />
                  </div>
                );
              }
            )}
          </div>
        </Form.Item>
      </div>
      <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8">
        <div className="md:w-36 text-left w-full flex md:flex-col flex-row items-center md:items-start gap-1">
          <span className="md:whitespace-pre-line">
            {t("formSetup.estimate.content")}
          </span>
        </div>
        <Form.Item
          name="pricing_note"
          layout="horizontal"
          className="mb-0 flex-1 w-full"
        >
          <TextArea
            autoSize
            placeholder={t("formSetup.estimate.contentPlaceholder")}
            maxLength={200}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default Estimate;

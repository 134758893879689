export enum PATH_ALBUM {
  DASHBOARD = "dashboard",
  SHOW = "show",
  ANO = "albums",
}

export enum DOWNLOAD_PHOTOS {
  ALL = "all",
  RECOMMEND = "is_recommended",
  LIKE = "is_liked",
  COMMENT = "is_commented",
  TAG = "tag_ids",
}

export enum DOWNLOAD_TXT {
  COMMA = "comma",
  SPACE = "space",
  DOWNLINE = "downline",
  EXTENTION = "extension",
  COMMENT = "comment",
}

export enum ACTION_ALBUM {
  CREATE = "create",
  EDIT = "edit",
  DOWNLOAD_PHOTOS = "download-photos",
  DOWNLOAD_TXT = "download-txt",
  SHARE = "share",
  LOCK = "lock",
  FILTER_LOCAL = "filter-local",
}

export const DEFAULT_PERPAGE_ALBUM = 12;

import { Form, Input, Radio } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { Edit3 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { REGEX_URL_IMAGE, REGEX_URL_YOUTUBE } from "../../constants/commons";
import { DEFAULT_INTRBG, RANDOM_INTRO_CONTENT } from "../../constants/website";
import { useRootSelector } from "../../stores/reducers/root";
import { setSetupWebsite } from "../../stores/reducers/website.reducer";
import { ISetupWebsiteFormProps } from "../../types/website.type";
import UploadPhoto from "../UploadPhoto";
import UploadVideo from "../UploadVideo";

const Introduce = ({ form }: ISetupWebsiteFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { intro_images: dfIntroImages, intro_background: dfIntroBg } =
    useRootSelector((state) => state.website);
  const [introBg, setIntroBg] = useState<
    Record<`${DEFAULT_INTRBG}` | "active", string>
  >({
    photo: null,
    video: null,
    active: DEFAULT_INTRBG.PHOTO,
  });
  const handleRandomContent = () => {
    const randomIndex = Math.floor(Math.random() * RANDOM_INTRO_CONTENT.length);
    const newContent = RANDOM_INTRO_CONTENT[randomIndex];
    form.setFieldsValue({ intro_content: newContent });
  };

  useEffect(() => {
    if (!!dfIntroBg) {
      if (REGEX_URL_IMAGE.test(dfIntroBg)) {
        setIntroBg((pre) => ({ ...pre, photo: dfIntroBg }));
      } else if (REGEX_URL_YOUTUBE.test(dfIntroBg)) {
        setIntroBg((pre) => ({
          ...pre,
          video: dfIntroBg,
          active: DEFAULT_INTRBG.VIDEO,
        }));
      }
    }
  }, [dfIntroBg]);

  return (
    <>
      <div className="flex flex-col gap-4">
        <div
          id="intro_background"
          className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8"
        >
          <span className="relative before:content-['*'] before:text-error before:mr-1 before:inline-block before:text-[14px] before:font-[SimSun] before:leading-none block md:w-36 text-left w-full">
            {t("formSetup.introduce.background")}
          </span>
          <Form.Item
            name="intro_background"
            layout="horizontal"
            className="mb-0 flex-1 w-full hidden"
          >
            <Input hidden />
          </Form.Item>
          <Radio.Group
            name="intro_background"
            value={introBg.active}
            onChange={(e) => {
              setIntroBg((pre) => ({ ...pre, active: e.target.value }));
              dispatch(
                setSetupWebsite({
                  intro_background: introBg[e.target.value],
                })
              );
            }}
            className={`flex items-center gap-0 sm:gap-10 lg:gap-20 sm:justify-start justify-between relative ${
              !dfIntroBg ? "mb-1" : ""
            }`}
          >
            <div className="flex flex-col justify-center items-center gap-2">
              <Radio disabled className="m-0">
                <div className="w-32 sm:w-44 lg:w-48 h-24 sm:h-32 -mx-2">
                  <UploadPhoto
                    dfUrl={introBg.photo}
                    onValueChange={(data) => {
                      introBg.active === DEFAULT_INTRBG.PHOTO &&
                        dispatch(
                          setSetupWebsite({
                            intro_background: data,
                          })
                        );
                      setIntroBg((pre) => ({
                        ...pre,
                        photo: data,
                      }));
                    }}
                    content={t("formSetup.introduce.addPhoto")}
                  />
                </div>
              </Radio>
              <Radio value={DEFAULT_INTRBG.PHOTO}>
                {t("formSetup.introduce.photo")}
              </Radio>
            </div>
            <div className="flex flex-col justify-center items-center gap-2">
              <Radio disabled className="m-0">
                <div className="w-32 sm:w-44 lg:w-48 h-24 sm:h-32 -mx-2">
                  <UploadVideo
                    onValueChange={(data) => {
                      introBg.active === DEFAULT_INTRBG.VIDEO &&
                        dispatch(
                          setSetupWebsite({
                            intro_background: data,
                          })
                        );
                      setIntroBg((pre) => ({
                        ...pre,
                        video: data,
                      }));
                    }}
                    dfValue={introBg.video}
                  />
                </div>
              </Radio>
              <Radio value={DEFAULT_INTRBG.VIDEO}>
                {t("formSetup.introduce.video")}
              </Radio>
            </div>
            {!dfIntroBg && (
              <Radio
                className="absolute -bottom-4 !text-error text-xs -ml-2"
                disabled
              >
                {t("formSetup.introduce.requireBackground")}
              </Radio>
            )}
          </Radio.Group>
        </div>
        <div className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8">
          <div className="">
            <span className="relative before:content-['*'] before:text-error before:mr-1 before:inline-block before:text-[14px] before:font-[SimSun] before:leading-none md:whitespace-pre-line block md:w-36 text-left w-full">
              {t("formSetup.introduce.content")}
            </span>
            <span
              onClick={handleRandomContent}
              className="text-secondary flex gap-1 items-end cursor-pointer hover:opacity-70 transition-all"
            >
              {t("formSetup.introduce.autoWrite")}
              <Edit3 className="w-4" />
            </span>
          </div>
          <Form.Item
            name="intro_content"
            layout="horizontal"
            className="mb-2 flex-1 w-full"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                    {t("formSetup.introduce.requireContent")}
                  </span>
                ),
              },
            ]}
          >
            <TextArea
              placeholder={t("formSetup.introduce.contentPlaceholder")}
              maxLength={2000}
              autoSize={{ minRows: 5 }}
              showCount
            />
          </Form.Item>
        </div>
        <div
          id="intro_images"
          className="flex flex-col md:flex-row justify-between sm:justify-start md:items-center w-full gap-2 md:gap-6 lg:gap-8"
        >
          <span className="relative before:content-['*'] before:text-error before:mr-1 before:inline-block before:text-[14px] before:font-[SimSun] before:leading-none block md:w-36 text-left w-full">
            {t("formSetup.introduce.photos")}
          </span>
          <Form.Item
            name="intro_images"
            layout="horizontal"
            className={`${
              !dfIntroImages.find((item) => !!item) ? "mb-2" : "mb-0"
            } flex-1 w-full`}
          >
            <div className="relative flex items-center justify-between lg:justify-start lg:gap-20">
              {dfIntroImages.map((item, index) => (
                <div className="w-32 sm:w-44 lg:w-48 h-24 sm:h-32" key={index}>
                  <UploadPhoto
                    dfUrl={item}
                    onValueChange={(data) => {
                      const updatedImages = [...dfIntroImages];
                      updatedImages[index] = data;
                      dispatch(
                        setSetupWebsite({
                          intro_images: updatedImages,
                        })
                      );
                    }}
                    content={t("formSetup.introduce.addPhoto")}
                  />
                </div>
              ))}
              {!dfIntroImages.find((item) => !!item) && (
                <span className="text-xs absolute block text-error -bottom-5">
                  {t("formSetup.introduce.requirePhoto")}
                </span>
              )}
            </div>
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default Introduce;
